import { useState, useEffect } from "react";
import useAuth from "../../../../../../hooks/useAuth";
import useAxiosPrivate from '../../../../../../hooks/useAxiosPrivate';
import { useNavigate } from "react-router-dom";
import Utils from "../../../../../../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo, faCreditCard, faMoneyBill } from '@fortawesome/free-solid-svg-icons'
import { faXmarkCircle } from '@fortawesome/free-regular-svg-icons'
import MainWrapper from './../../../layouts/MainWrapper';
import InputLabeled from "../../../../../Admin/components/form/InputLabeled";
import SelectLabeled from "../../../../../Admin/components/form/SelectLabeled";
import ButtonU from "../../../../../Admin/components/ui/ButtonU";
import { useTranslation } from "react-i18next";
import Config from "../../../../../../config";
import { Skeleton } from "@mui/material";
import TermTypeRadioBtn from "./../components/TermTypeRadioBtn";
import PaymentTypeRadioBtn from "./../components/PaymentTypeRadioBtn";
import BankRadioBtn from "./../components/BankRadioBtn";
import SelectBankAccount from "./../components/SelectBankAccount";
import RequiredDocument from "./components/RequiredDocument";

const NewAppointment = () => {
    const { t } = useTranslation();
    const { ready, auth } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [serviceId, setServiceId] = useState('');
    const [countryId, setCountryId] = useState('');
    const [visaTypeId, setVisaTypeId] = useState('');
    const [email, setEmail] = useState('');
    const [photo, setPhoto] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [passportNo, setPassportNo] = useState('');
    const [passportExpireDate, setPassportExpireDate] = useState('');
    const [dob, setDob] = useState('');
    const [termTypeId, setTermTypeId] = useState('');
    const [preparationTimeId, setPreparationTimeId] = useState('');
    const [paymentType, setPaymentType] = useState('CASH');
    const [bankId, setBankId] = useState('');
    const [bankAccountId, setBankAccountId] = useState('');

    const [filePhoto, setFilePhoto] = useState();

    const [countries, setCountries] = useState([]);
    const [visaTypes, setVisaTypes] = useState([]);
    const [services, setServices] = useState([]);
    const [service, setService] = useState({});
    const [requiredDocuments, setRequiredDocuments] = useState([]);
    const [banks, setBanks] = useState([]);
    const [bankAccounts, setBankAccounts] = useState([]);

    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    const breadcrumbs = [
        { title: t('MAIN'), link: `${Config.ADMIN_PREFIX}/`}, { title: t('APPOINTMENTS'), link: `${Config.ADMIN_PREFIX}/appointments`}, { title: t('NEW'), active: true }
    ]

    const paymentTypes = [
        { id: 'CASH', name: t('CASH'), icon: faMoneyBill },
        { id: 'BANK', name: t('BANK'), icon: faCreditCard },
    ];

    useEffect(() => {
        if (ready) {
            getCountries();
            getVisaTypes();
            getServices();
            getBanks();
        }
    }, [ready]);

    useEffect(() => {
        if (countryId && visaTypeId) {
            services.filter((item) => {
                if (item.countryId == countryId && item.visaTypeId == visaTypeId) {
                    setServiceId(item.id);
                }
            })
        }
    }, [countryId, visaTypeId]);

    useEffect(() => {
        if (serviceId)
            getService();
    }, [serviceId]);

    useEffect(() => {
        setBankId('');
        setBankAccountId('');
    }, [paymentType]);

    useEffect(() => {
        if (bankId) {
            getBankAccounts();
            setBankAccountId('');
        }
    }, [bankId]);

    const getCountries = () => {
        setLoading(true);
        axiosPrivate.get('/countries').then((res) => {
            if (res.status == 200) setCountries(res.data.data);
        }).finally(() => {setLoading(false)});
    }

    const getVisaTypes = () => {
        setLoading(true);
        axiosPrivate.get('/visa-types').then((res) => {
            if (res.status == 200) setVisaTypes(res.data.data);
        }).finally(() => {setLoading(false)});
    }

    const getServices = () => {
        setLoading(true);
        axiosPrivate.get('/services').then((res) => {
            if (res.status == 200) setServices(res.data.data);
        }).finally(() => {setLoading(false)});
    }

    const getService = () => {
        setLoading(true);
        axiosPrivate.get(`/services/${serviceId}`).then((res) => {
            if (res.status == 200) {
                setService(res.data);
                setRequiredDocuments(res.data.requiredDocuments);
            }
        }).finally(() => {setLoading(false)});
    }

    const getBanks = () => {
        setLoading(true);
        axiosPrivate.get('/banks').then((res) => {
            if (res.status == 200) setBanks(res.data.data);
        }).finally(() => {setLoading(false)});
    }

    const getBankAccounts = () => {
        setLoading(true);
        const query = { filter: { bankId } }
        axiosPrivate.get('/bank-accounts', { params: query }).then((res) => {
            if (res.status == 200) setBankAccounts(res.data.data);
        }).finally(() => {setLoading(false)});
    }

    const clearService = () => {
        setCountryId('');
        setVisaTypeId('');
        setService({});
        setServiceId('');
        setTermTypeId('');
        setPreparationTimeId('');
    }

    const clearPrivateInfo = () => {
        setFirstName('');
        setLastName('');
        setEmail('');
        setPhoneNumber('');
        setDob('');
        setPassportNo('');
        setPassportExpireDate('');
    }

    const handleFilePhoto = (e) => {
        setPhoto(URL.createObjectURL(e.target.files[0]));
        setFilePhoto(e.target.files[0]);
    }

    const handleDocumentChange = (event, index) => {
        const updatedRequiredDocuments = [...requiredDocuments]; // Create a copy
        updatedRequiredDocuments[index].file = event.target.files[0]; // Update specific paidAmount
        setRequiredDocuments(updatedRequiredDocuments); // Update state with modified array
    };
    
    const handleSubmit = async() => {
        try {
            const requiredInputs = [
                { key: serviceId, value: t('SERVICE_REQUIRED') },
                { key: termTypeId, value: t('TERM_TYPE_REQUIRED') },
                { key: firstName, value: t('NAME_REQUIRED') },
                { key: lastName, value: t('LASTNAME_REQUIRED') },
                { key: email, value: t('EMAIL_REQUIRED') },
                { key: phoneNumber, value: t('PHONE_NUMBER_REQUIRED') },
                { key: dob, value: t('DOB_REQUIRED') },
                { key: passportNo, value: t('PASSPORT_NO_REQUIRED') },
                { key: passportExpireDate, value: t('PASSPORT_EXPIRE_DATE_REQUIRED') },
                { key: !(paymentType == 'BANK' && !bankAccountId), value: t('BANK_ACCOUNT_REQUIRED') },
                { key: auth.user.id, value: t('USER_REQUIRED') },
            ]

            const content = Utils.errorHandling(requiredInputs);
            if (content) {
                setToast({ content, variant: 'danger', title: t('ERROR'), show: true });
                return;
            }

            setLoading(true);

            const requiredDocumentsId = [];
            requiredDocuments.map(item => requiredDocumentsId.push({id: item.id}) );

            const data = {
                userId: auth.user.id, serviceId, firstName, lastName, email, phoneNumber,
                dob, passportNo, passportExpireDate, termTypeId, preparationTimeId, paymentType,
                bankAccountId, requiredDocumentsId, 
                photo: filePhoto
            };

            const headers = { 'Content-Type': 'multipart/form-data'};
            const res = await axiosPrivate.post('/appointments/create', data, {headers});

            if (res.status === 200) {
                const appointmentDocuments = res.data.appointmentDocuments;
                await prepareDocumentsForCreating(appointmentDocuments);
                setToast({ content: t('UPLOADED'), variant: 'success', title: t('SUCCESS'), show: true });
                navigate(-1);
            }
        } catch (err) {
            if (!err?.response) setToast({ content: t('NO_SERVER_RESPONSE'), variant: 'danger', title: t('ERROR'), show: true });
            else setToast({ content: err.response.data.msg, variant: 'danger', title: t('ERROR'), show: true });
        } finally { setLoading(false) }
    }

    const prepareDocumentsForCreating = async(appointmentDocuments) => {
        for (const item of appointmentDocuments) {
            const requiredDocument = requiredDocuments.find(doc => doc.id === item.requiredDocumentId);
            if (!requiredDocument.file) continue;
            const data = {
                id: item.id,
                path: Config.PATH_APPOINTMENT_DOCS,
                file: requiredDocument.file
            };
            await updateAppointmentDocument(data);
        }
    }

    const updateAppointmentDocument = async (data) => {
        try {
            setLoading(true);
            const headers = { 'Content-Type': 'multipart/form-data' };
            const res = await axiosPrivate.put(`/appointment-documents/update/${data.id}`, data, { headers });
    
            if (res.status === 200)
                setToast({ content: t('SUCCESS'), variant: 'success', title: t('SUCCESS'), show: true });
        } catch (err) {
            if (!err?.response) setToast({ content: t('NO_SERVER_RESPONSE'), variant: 'danger', title: t('ERROR'), show: true });
            else setToast({ content: err.response.data.msg, variant: 'danger', title: t('ERROR'), show: true });
        } finally { setLoading(false) }
    }

    return (
        <MainWrapper loading={loading} breadcrumbs={breadcrumbs} toast={toast} setToast={setToast}>
            <div className="head-long-page">
                <span className="bold fs-5 fg-dark">{`${t('NEW')} ${t('APPOINTMENT')}`}</span>
                <div className="d-flex align-items-center">
                    <ButtonU onSubmit={() => navigate(-1)} className={'button-u text-white bg-red-400'} text={t('CANCEL')}/>
                    <ButtonU onSubmit={handleSubmit} className={'button-u bg-blue-600 text-white'} text={t('SAVE')}/>
                </div>
            </div>
            <div className="mt-5 bg-white px-4 pb-4 py-2 row mx-0">
                <div className="d-flex flex-row align-items-center justify-content-between">
                    <span className="fg-dark medium fs-5">{t('SERVICE')}</span>
                    <button onClick={clearService} className="btn fg-red-300 font-13">
                        <FontAwesomeIcon icon={faXmarkCircle} className="me-1"/>
                        {t('CLEAR')}
                    </button>
                </div>
                {service.id ? <div className="d-flex flex-row align-items-center col-md-12 mt-2">
                    <span className="fg-dark">{service.country.name}</span>
                    <img src={`${Config.URL_SERVER}${Config.PATH_PREFIX}${Config.PATH_COUNTRY_FLAGS}${service.country && service.country.flag}`} className="country-flag-sm ms-2"/>
                    <span className="fg-dark mx-1">/</span>
                    <span className="fg-dark">{service.visaType.name}</span>
                </div> : <Skeleton className="col-md-4"/>}
                {service.id && 
                    <TermTypeRadioBtn title={t('SELECT_TERM_TYPE')} items={service.termTypes} inline={false} group={t('TERM_TYPE')} attr={termTypeId} onChangeAttr={setTermTypeId} type={'radio'} className={'col-md-6 mt-3'} required={true}/>
                }
                {service.id && 
                    <TermTypeRadioBtn title={t('SELECT_PREPARATION_TIME')} items={service.preparationTimes} inline={false} group={t('PREPARATION_TYPE')} attr={preparationTimeId} onChangeAttr={setPreparationTimeId} type={'radio'} className={'col-md-6 mt-3'}/>
                }
                {!service.id && <div className="row align-items-center mt-3">
                    <SelectLabeled label={t('COUNTRY')} value={countryId} setValue={setCountryId} items={countries} required={true} className={'col-md-3'}/>
                    <SelectLabeled label={t('VISA_TYPE')} value={visaTypeId} setValue={setVisaTypeId} items={visaTypes} required={true} className={'col-md-3'}/>
                    {(countryId && visaTypeId && !serviceId) &&
                        <div className="col-md-3">
                            <span className="bg-red-400 badge">{t('NOT_FOUND')}</span>
                        </div>
                    }
                </div>}
                <hr className="my-3 fg-gray-50"/>
                <div className="d-flex flex-row align-items-center justify-content-between mb-2">
                    <span className="fg-dark medium fs-5">{t('PRIVATE_INFO')}</span>
                    <button onClick={clearPrivateInfo} className="btn fg-red-300 font-13">
                        <FontAwesomeIcon icon={faXmarkCircle} className="me-1"/>
                        {t('CLEAR')}
                    </button>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <span className="fg-dark font-13 medium me-1">{t('IMAGE')}</span>
                        <div className="d-flex flex-column my-2">
                            {photo ? <img src={photo} className="person-photo"/>
                            : <div className="image-upload bg-dark" />
                            }
                        </div>
                        <label htmlFor="uploadBtn" onChange={handleFilePhoto} className="upload-btn bg-blue-700 bg-gradient medium text-white mt-1">
                            {t('UPLOAD')}
                            <input id="uploadBtn" type="file" hidden/>
                        </label>
                    </div>
                    <div className="row mx-0 col-md-9">
                        <InputLabeled label={t('FIRSTNAME')} type={'text'} value={firstName} setValue={setFirstName} hint={t('FIRSTNAME')} className={'col-md-4'} required={true}/>
                        <InputLabeled label={t('LASTNAME')} type={'text'} value={lastName} setValue={setLastName} hint={t('LASTNAME')} className={'col-md-4'} required={true}/>
                        <InputLabeled label={t('EMAIL')} type={'text'} value={email} setValue={setEmail} hint={t('tkmvisa@gmail.com')} className={'col-md-4'} required={true}/>
                        <InputLabeled label={t('PHONE_NUMBER')} type={'number'} value={phoneNumber} setValue={setPhoneNumber} hint={t('+901234567890')} className={'col-md-4'} required={true}/>
                        <InputLabeled label={t('DOB')} type={'date'} value={dob} setValue={setDob} className={'col-md-4'} required={true}/>
                        <div className="col-md-4"></div>
                        <InputLabeled label={t('PASSPORT_NO')} type={'text'} value={passportNo} setValue={setPassportNo} hint={t('A123456')} className={'col-md-4'} required={true}/>
                        <InputLabeled label={t('PASSPORT_VALID_PERIOD')} type={'date'} value={passportExpireDate} setValue={setPassportExpireDate} className={'col-md-4'} required={true}/>
                    </div>
                </div>
                <hr className="my-3 fg-gray-50"/>
                {serviceId && <div className="d-flex flex-column mb-2">
                    <span className="fg-dark medium fs-5 mb-2">{t('REQUIRED_DOCUMENTS')}</span>
                    <span className="font-13 medium fg-blue-300">
                        <FontAwesomeIcon icon={faCircleInfo} className="me-2"/>
                        {t('UPLOAD_DOCUMENTS_BELOW')}
                    </span>
                </div>}
                {requiredDocuments.length > 0 && <div className="row">
                    {requiredDocuments.map((item, index) => {
                        return(<RequiredDocument key={index} item={item} onChange={(event) => handleDocumentChange(event, index)}/>)
                    })}
                </div>}
                <hr className="my-3 fg-gray-50"/>
                <span className="fg-dark medium fs-5 mb-2">{t('PAYMENT_INFO')}</span>
                <PaymentTypeRadioBtn items={paymentTypes} inline={false} group={t('PAYMENT_TYPE')} attr={paymentType} onChangeAttr={setPaymentType} type={'radio'} required={true}/>
                {paymentType == 'BANK' && <BankRadioBtn items={banks} inline={false} group={t('BANK')} attr={bankId} onChangeAttr={setBankId} type={'radio'} required={true}/>}
                {bankAccounts.length > 0 && <SelectBankAccount label={t('BANK_ACCOUNT')} value={bankAccountId} setValue={setBankAccountId} items={bankAccounts} required={true} className={'col-md-6 mt-3'}/>}
            </div>
        </MainWrapper>
    );
}

export default NewAppointment;