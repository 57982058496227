import { useEffect, useState } from "react";
import useAxiosPrivate from '../../../../../../../hooks/useAxiosPrivate';
import ModalU from '../../../../../components/ui/ModalU';
import Utils from "../../../../../../../utils";
import InputLabeled from '../../../../../components/form/InputLabeled';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useTranslation } from "react-i18next";
import SelectType from "./components/SelectType";

const Create = ({...props}) => {
    const { t } = useTranslation();
    const axiosPrivate = useAxiosPrivate();
    
    const [type, setType] = useState('');
    const [version, setVersion] = useState('');
    const [content, setContent] = useState('');

    const types = [
        {type: 'TERMS_OF_SERVICE', value: t('TERMS_OF_SERVICE')},
        {type: 'PRIVACY_POLICY', value: t('PRIVACY_POLICY')},
        {type: 'CONTRACT', value: t('CONTRACT')}
    ];

    const modules = {
        toolbar: [
          [{ 'header': [1, 2, false] }],
          ['bold', 'italic', 'underline','strike', 'blockquote'],
          [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
          ['link'], ['clean'],
          [{ "color": ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#f00f66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466"] }],
        ],
    }
    
    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'color'
    ]

    const handleSubmit = (event) => {
        event.preventDefault();
        
        const requiredInputs = [
            { key: type, value: t('TYPE') },
            { key: version, value: t('VERSION') },
            { key: content, value: t('CONTENT') },
        ]

        const errorContent = Utils.errorHandling(requiredInputs);
        if (errorContent) {
            props.setToast({ errorContent, variant: 'danger', title: t('ERROR'), show: true });
            return;
        }

        props.setLoading(true);
        const data = { type, version, content };
        axiosPrivate.post('/policy-documents/create', data).then((res) => {
            if (res.status == 200) {
                props.setModalShow(false);
                props.setToast({ content: t('SUCCESS'), variant: 'success', title: t('SUCCESS'), show: true });
            }
        }).catch((err) => {
            if (!err?.response) props.setToast({ content: t('NO_SERVER_RESPONSE'), variant: 'danger', title: t('ERROR'), show: true });
            else props.setToast({ content: err.response.data.msg, variant: 'danger', title: t('ERROR'), show: true });
        }).finally(() => {props.setLoading(false)});
    }

    return (
        <ModalU show={props.modalShow} size={'lg'} title={t('POLICY_DOCUMENT')}
            actionbtntext={t('ADD')}
            actionbtnclassname={"bg-blue-700 font-14 medium text-white"}
            onSubmit={handleSubmit} onHide={props.setModalShow}>
                
            <form onSubmit={handleSubmit}>
                <div className="d-flex flex-row">
                    <SelectType label={t('TYPE')} items={types} value={type} setValue={setType} className={'me-4'}/>
                    <InputLabeled label={t('VERSION')} type={'text'} value={version} setValue={setVersion} hint={t('1.0.0')} required={true}/>
                </div>
                <div className="pt-3 pb-2">
                    <span className="fg-dark font-18 medium">{t('CONTENT')}</span>
                </div>
                <div className="border border-secondary bg-light p-2" style={{height: '300px', overflowY: 'auto'}}>
                    <ReactQuill theme="snow" className="bg-white"
                        value={content} onChange={setContent}
                        modules={modules} formats={formats}/>
                </div>
            </form>
        </ModalU>
    );
}

export default Create