import { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import useAuth from './../../hooks/useAuth';
import Config from "../../config";
import Header from "./Header";
import ToastU from "./../../pages/Admin/components/ui/ToastU";

const Wrapper = (props) => {
    const navigate = useNavigate();
    const { auth, ready } = useAuth();

    useEffect(() => {
        if (auth.token == undefined && !auth.token) return;
        if (auth.token == undefined || auth.token == 'Unauthorized') {
            navigate(`${Config.ADMIN_PREFIX}/auth/signin`);
        }
    }, [auth, ready]);

    return (
        ready && <div className="d-flex flex-column">
            <Header rootPage={props.rootPage} loading={props.loading} breadcrumbs={props.breadcrumbs}/>
            { props.children }
            {props.toast && <ToastU toast={props.toast} setToast={props.setToast}/>}
        </div>
    );
}

export default Wrapper;