import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareXmark } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from 'react-i18next';

const DataNotFound = () => {
    const { t } = useTranslation();
   
    return (
        <div className="d-flex flex-column align-items-center justify-content-center m-5">
            <FontAwesomeIcon icon={faSquareXmark} className="fs-3 text-secondary mt-5"/>
            <span className="fs-4 text-secondary">{t('DATA_NOT_FOUND')}</span>
        </div>
    );
}

export default DataNotFound;