import {  useState } from "react";
import useAxiosPrivate from '../../../../../../../hooks/useAxiosPrivate';
import ModalU from '../../../../../components/ui/ModalU';
import Utils from "../../../../../../../utils";
import InputLabeled from '../../../../../components/form/InputLabeled';
import { useTranslation } from "react-i18next";

const Create = ({...props}) => {
    const { t } = useTranslation();
    const axiosPrivate = useAxiosPrivate();
    
    const [name, setName] = useState('');
    const [code, setCode] = useState('');
    
    const handleSubmit = (event) => {
        event.preventDefault();
        
        const requiredInputs = [
            { key: name, value: t('NAME_REQUIRED') },
            { key: code, value: t('CODE_REQUIRED') },
        ]

        const content = Utils.errorHandling(requiredInputs);
        if (content) {
            props.setToast({ content, variant: 'danger', title: t('ERROR'), show: true });
            return;
        }

        props.setLoading(true);
        const data = { name, code };

        axiosPrivate.post('/currencies/create', data).then((res) => {
            if (res.status == 200) {
                props.setModalShow(false);
                props.setToast({ content: t('SUCCESS'), variant: 'success', title: t('SUCCESS'), show: true });
            }
        }).catch((err) => {
            if (!err?.response) props.setToast({ content: t('NO_SERVER_RESPONSE'), variant: 'danger', title: t('ERROR'), show: true });
            else props.setToast({ content: err.response.data.msg, variant: 'danger', title: t('ERROR'), show: true });
        }).finally(() => {props.setLoading(false)});
    }

    return (
        <ModalU show={props.modalShow} size={''} title={t('CURRENCY')}
            actionbtntext={t('SAVE')}
            actionbtnclassname={"bg-sp-primary font-14 medium fg-dark"}
            onSubmit={handleSubmit} onHide={props.setModalShow}>
                
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <InputLabeled label={t('NAME')} type={'text'} value={name} setValue={setName} hint={'Turkmen Manat'} required={true} className={'col-md-8'}/>
                    <InputLabeled label={t('CODE')} type={'text'} value={code} setValue={setCode} hint={'TMT'} required={true} className={'col-md-4'}/>
                </div>
            </form>
        </ModalU>
    );
}

export default Create