import { useEffect, useState } from "react";
import useAxiosPrivate from './../../../../../../../hooks/useAxiosPrivate';
import ModalU from './../../../../../../Admin/components/ui/ModalU';
import Utils from "./../../../../../../../utils";
import InputLabeled from './../../../../../../Admin/components/form/InputLabeled';
import { useTranslation } from "react-i18next";

const Update = ({...props}) => {
    const { t } = useTranslation();
    const axiosPrivate = useAxiosPrivate();
    const item = props.item;
    
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState();
    const [addressLine, setAddressLine] = useState();


    useEffect(() => {
        setEmail(item.email);
        setPhoneNumber(item.phoneNumber);
        setAddressLine(item.addressLine);
    }, [item])

    const handleSubmit = (event) => {
        event.preventDefault();
        
        const requiredInputs = [
            { key: email, value: t('EMAIL_REQUIRED') },
            { key: phoneNumber, value: t('PHONE_NUMBER_REQUIRED') },
            { key: addressLine, value: t('ADDRESS_LINE_REQUIRED') },
        ]

        const content = Utils.errorHandling(requiredInputs);
        if (content) {
            props.setToast({ content, variant: 'danger', title: t('ERROR'), show: true });
            return;
        }

        props.setLoading(true);
        const data = { email, phoneNumber, addressLine };
        axiosPrivate.put('/contacts/update/'+item.id, data).then((res) => {
            if (res.status == 200) {
                props.setModalShow(false);
                props.setToast({ content: t('SUCCESS'), variant: 'success', title: t('SUCCESS'), show: true });
            }
        }).catch((err) => {
            if (!err?.response) props.setToast({ content: t('NO_SERVER_RESPONSE'), variant: 'danger', title: t('ERROR'), show: true });
            else props.setToast({ content: err.response.data.msg, variant: 'danger', title: t('ERROR'), show: true });
        }).finally(() => {props.setLoading(false)});
    }

    return (
        <ModalU show={props.modalShow} size={''} title={`${t('CONTACT')} [${t('EDIT')}]`}
            actionbtntext={t('UPDATE')}
            actionbtnclassname={"bg-green-700 font-14 medium text-white"}
            onSubmit={handleSubmit} onHide={props.setModalShow}>
                
            <form onSubmit={handleSubmit}>
                <InputLabeled label={t('NAME')} type={'text'} value={email} setValue={setEmail} hint={t('NAME')} required={true}/>
                <div className="d-flex row">
                    <div className="col-md-6">
                        <InputLabeled label={t('PHONE_NUMBER')} type={'tel'} value={phoneNumber} setValue={setPhoneNumber} hint={t('PHONE_NUMBER')} required={true}/>
                    </div>
                    <div className="col-md-6">
                        <InputLabeled label={t('ADDRESS')} type={'text'} value={addressLine} setValue={setAddressLine} hint={t('ADDRESS')} required={true}/>
                    </div>
                </div>
            </form>
        </ModalU>
    );
}

export default Update