import { useEffect, useState } from "react";
import useAxiosPrivate from './../../../../../hooks/useAxiosPrivate';
import Config from "../../../../../config";
import ModalU from './../../../../Admin/components/ui/ModalU';
import Utils from "./../../../../../utils";
import InputLabeled from './../../../../Admin/components/form/InputLabeled';
import { useTranslation } from "react-i18next";

const Update = ({...props}) => {
    const { t } = useTranslation();
    const axiosPrivate = useAxiosPrivate();
    const item = props.item;
    
    const [title, setTitle] = useState('');
    const [image, setImage] = useState('');
    const [file, setFile] = useState();

    useEffect(() => {
        setTitle(item.title);
        setImage(item.image);
    }, [item]);

    const handleFile = (e) => {
        setImage(URL.createObjectURL(e.target.files[0]));
        setFile(e.target.files[0]);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        
        const requiredInputs = [
            { key: title, value: t('TITLE_REQUIRED') },
            { key: image, value: t('IMAGE_REQUIRED') },
        ]

        const content = Utils.errorHandling(requiredInputs);
        if (content) {
            props.setToast({ content, variant: 'danger', title: t('ERROR'), show: true });
            return;
        }

        props.setLoading(true);
        const data = { title, path: Config.PATH_BANNERS, filename: item.image, file };
        
        const headers = { 'Content-Type': 'multipart/form-data'};
        axiosPrivate.put('/banners/update/'+item.id, data, {headers}).then((res) => {
            if (res.status == 200) {
                props.setModalShow(false);
                props.setToast({ content: t('SUCCESS'), variant: 'success', title: t('SUCCESS'), show: true });
            }
        }).catch((err) => {
            if (!err?.response) props.setToast({ content: t('NO_SERVER_RESPONSE'), variant: 'danger', title: t('ERROR'), show: true });
            else props.setToast({ content: err.response.data.msg, variant: 'danger', title: t('ERROR'), show: true });
        }).finally(() => {props.setLoading(false)});
    }

    return (
        <ModalU show={props.modalShow} size={''} title={`${t('BANNER')} [${t('EDIT')}]`}
            actionbtntext={t('UPDATE')}
            actionbtnclassname={"bg-green-700 font-14 medium text-white"}
            onSubmit={handleSubmit} onHide={props.setModalShow}>
                
            <form onSubmit={handleSubmit}>
                <div className="d-flex flex-column">
                    <InputLabeled label={t('TITLE')} type={'text'} value={title} setValue={setTitle} hint={t('TITLE')} required={true}/>
                    <div className='d-flex flex-row mt-3'>
                        { image && !file ?
                            <img src={`${Config.URL_SERVER}${Config.PATH_PREFIX}${Config.PATH_BANNERS}${item.image}`} className="wh-200 rounded-3 border" style={{height: '120px'}}/>
                            :
                            <img src={image} className="wh-200 rounded-3 border mt-2" style={{height: '120px'}}/>
                        }
                        <div className="d-flex flex-column ms-3">
                            <span className="fg-dark">{t('IMAGE')}</span>
                            <span className="fg-gray font-12">{t('BANNER_FORMAT_SIZE_DESC')}</span>
                            <div>
                                <label htmlFor="uploadBtn" onChange={handleFile} className="upload-btn bg-blue-600 text-white mt-3">
                                    {t('UPLOAD')}
                                    <input id="uploadBtn" accept="image/png" type="file" hidden/>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </ModalU>
    );
}

export default Update