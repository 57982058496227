import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import useAuth from "../../../../../../../hooks/useAuth";
import useAxiosPrivate from '../../../../../../../hooks/useAxiosPrivate';
import DataWrapper from "../../DataWrapper";
import TableStd from '../../../../../../Admin/components/ui/TableStd';
import DataNotFound from '../../../../../../Admin/components/ui/DataNotFound';
import Status from "../../../../../../Admin/components/ui/Status";
import { useTranslation } from "react-i18next";
import Config from "../../../../../../../config";
import Utils from './../../../../../../../utils';

const Detail = () => {
    const { t } = useTranslation();
    const params = useParams();
    const { ready } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const [loading, setLoading] = useState(false);

    const [service, setService] = useState({});

    const breadcrumbs = [
        { title: t('SETTINGS'), link: `${Config.ADMIN_PREFIX}/settings/profile`},
        { title: t('DATABASE'), link: `${Config.ADMIN_PREFIX}/settings/data/general`},
        { title: t('GENERAL'), link: `${Config.ADMIN_PREFIX}/settings/data/general` },
        { title: t('SERVICES'), link: `${Config.ADMIN_PREFIX}/settings/data/general/services` },
        { title: `${service.country && service.country.name} (${service.visaType && service.visaType.name})`, active: true }
    ]

    useEffect(() => {
        if (ready) {
            getService();
        }
    }, [ready]);

    const getService = () => {
        setLoading(true);
        axiosPrivate.get('/services/'+params.id).then((res) => {
            if (res.status == 200) {
                setService(res.data);
            }
        }).finally(() => {setLoading(false)});
    }

    return (
        <DataWrapper loading={loading} breadcrumbs={breadcrumbs}>
            <div className="head-long-page">
                <span className="bold fs-5 fg-dark">{`${service.country && service.country.name} (${service.visaType && service.visaType.name})`}</span>
                <span></span>
            </div>
            <div className="d-flex flex-column bg-white p-3 mt-5">
                <div className="row my-1">
                    <span className="col-md-4 medium fg-gray">{t('COUNTRY')}</span>
                    <span className="col-md-8 fg-dark align-items-center">
                        {service.country && service.country.name}
                        <img src={`${Config.URL_SERVER}${Config.PATH_PREFIX}${Config.PATH_COUNTRY_FLAGS}${service.country && service.country.flag}`} className="country-flag-sm ms-2"/>
                    </span>
                </div>
                <div className="row my-1">
                    <span className="col-md-4 medium fg-gray">{t('VISA_TYPE')}</span>
                    <span className="col-md-8 fg-dark align-items-center">
                        {service.visaType && service.visaType.name}
                    </span>
                </div>
                <div className="row my-1">
                    <span className="col-md-4 medium fg-gray">{t('STATUS')}</span>
                    <div className="col-md-8">
                        <Status status={service.active} textSize={'font-16'}/>
                    </div>
                </div>
                <div className="row my-1">
                    <span className="col-md-4 medium fg-gray">{t('CREATED_DATE')}</span>
                    <span className="col-md-8 fg-dark align-items-center">
                        {Utils.getTimestampDate(service.createdAt)}
                    </span>
                </div>
                <hr className="my-2"/>
                <div className="row my-1">
                    <span className="col-md-4 medium fg-gray">{t('TERM_TYPES')}</span>
                    <div className="col-md-8 d-flex flex-column">
                        { service.termTypes && service.termTypes.map((item, index) => {
                            return (
                                <div className="d-flex flex-row">
                                    <span className="fg-dark me-2">{index + 1})</span>
                                    <span className="fg-dark">{item.name},</span>
                                    <span className="ms-2 fg-green-500">${item.price}</span>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <hr className="my-2"/>
                <div className="row my-1">
                    <span className="col-md-4 medium fg-gray">{t('PREPARATION_TIMES')}</span>
                    <div className="col-md-8 d-flex flex-column">
                        { service.preparationTimes && service.preparationTimes.map((item, index) => {
                            return (
                                <div className="d-flex flex-row">
                                    <span className="fg-dark me-2">{index + 1})</span>
                                    <span className="fg-dark">{item.name},</span>
                                    <span className="ms-2 fg-green-500">${item.price}</span>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <hr className="my-2"/>
                <div className="row my-1">
                    <span className="col-md-4 medium fg-gray">{t('REQUIRED_DOCUMENTS')}</span>
                    <div className="col-md-8 d-flex flex-column">
                        { service.requiredDocuments && service.requiredDocuments.map((item, index) => {
                            return (
                                <div className="d-flex flex-row">
                                    <span className="fg-dark me-2">{index + 1})</span>
                                    <span className="fg-dark">{item.documentType.name}</span>
                                    {item.additionalText && <span className="fg-dark ms-2">({item.additionalText})</span>}
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            <div className="mb-5"></div>
        </DataWrapper>
    );
}

export default Detail;