import { useState } from "react";
import convertSize from "convert-size";
import useAxiosPrivate from '../../../../../../../hooks/useAxiosPrivate';
import ModalU from '../../../../../components/ui/ModalU';
import Utils from "../../../../../../../utils";
import InputAreaLabeled from "../../../../../components/form/InputAreaLabeled";
import { useTranslation } from "react-i18next";
import Config from "../../../../../../../config";
import icPdf from "../../../../../../../assets/svg/ic_pdf.svg"

const AddReceipt = ({...props}) => {
    const { t } = useTranslation();
    const axiosPrivate = useAxiosPrivate();
    
    const appointment = props.appointment;

    const [note, setNote] = useState('');
    const [file, setFile] = useState();

    const handleFile = (e) => {
        setFile(e.target.files[0]);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        
        const requiredInputs = [
            { key: file, value: t('FILE_NOT_SELECTED') },
        ]

        const content = Utils.errorHandling(requiredInputs);
        if (content) {
            props.setToast({ content, variant: 'danger', title: t('ERROR'), show: true });
            return;
        }

        props.setLoading(true);
        const data = { appointmentId: appointment.id, path: Config.PATH_RECEIPTS, file, note };

        const headers = { 'Content-Type': 'multipart/form-data'};
        axiosPrivate.post('/appointment-payments/create', data, {headers}).then((res) => {
            if (res.status == 200) {
                props.setModalShow(false);
                props.setToast({ content: t('SUCCESS'), variant: 'success', title: t('SUCCESS'), show: true });
            }
        }).catch((err) => {
            if (!err?.response) props.setToast({ content: t('NO_SERVER_RESPONSE'), variant: 'danger', title: t('ERROR'), show: true });
            else props.setToast({ content: err.response.data.msg, variant: 'danger', title: t('ERROR'), show: true });
        }).finally(() => {props.setLoading(false)});
    }

    return (
        <ModalU show={props.modalShow} size={''} title={`${t('PAYMENT')} [${t('NEW')}]`}
            actionbtntext={t('ADD')}
            actionbtnclassname={"bg-blue-700 font-14 medium text-white"}
            onSubmit={handleSubmit} onHide={props.setModalShow}>
                
            <form onSubmit={handleSubmit}>
                <span className="fg-dark font-13 medium">{t('RECEIPT')}</span>
                <div className="d-flex flex-column align-items-center mb-2 bg-orange-50 p-3">
                    <img src={icPdf} alt="excel" className="wh-40"/>
                    {file && <div className="d-flex flex-column m-1">
                        <span className="text-secondary font-14">{t('NAME')}:<span className="fg-blue-500 ms-2">{file.name}</span></span>
                        <span className="text-secondary font-14">{t('SIZE')}:<span className="fg-green-500 medium ms-2">{convertSize(file.size, {accuracy: 3})}</span></span>
                    </div>}
                    <input id="uploadBtn" type="file" accept="application/pdf" className="small-x mt-3" onChange={handleFile}/>
                </div>
                <InputAreaLabeled label={t('NOTE')} rows={3} value={note} setValue={setNote} hint={t('NOTE')} required={false}/>
            </form>
        </ModalU>
    );
}

export default AddReceipt