import DataWrapper from "../DataWrapper";
import Option from "./../../../components/Option";
import icCardholder from "./../../../../../../assets/svg/ic_cardholder.svg";
import icBillCheck from "./../../../../../../assets/svg/ic_bill_check.svg";
import icEuro from "./../../../../../../assets/svg/ic_euro.svg";
import { useTranslation } from "react-i18next";
import Config from "../../../../../../config";

const Finance = () => {
    const { t } = useTranslation();

    const breadcrumbs = [
        { title: t('SETTINGS'), link: `${Config.ADMIN_PREFIX}/settings/profile`}, { title: t('DATABASE'), link: `${Config.ADMIN_PREFIX}/settings/data/general`}, { title: t('FINANCE'), active: true }
    ]

    const options = [
        // { id: 'banks', name: t('BANKS'), icon: icCardholder, link: `${Config.ADMIN_PREFIX}/settings/data/finance/banks`},
        { id: 'bank_accounts', name: t('BANK_ACCOUNTS'), icon: icBillCheck, link: `${Config.ADMIN_PREFIX}/settings/data/finance/bank-accounts`},
        { id: 'currencies', name: t('CURRENCIES'), icon: icEuro, link: `${Config.ADMIN_PREFIX}/settings/data/finance/currencies`},
    ]

    return (
        <DataWrapper breadcrumbs={breadcrumbs}>
            <div className="row p-3">
                { options.map((option) => {
                    return (<Option key={option.id} item={option}/>)
                })}
            </div>
        </DataWrapper>
    );
}

export default Finance;