import { useTranslation } from 'react-i18next';

const PolicyDocumentTypes = () => {
    const { t } = useTranslation();

    const TYPES = new Map([
        ['TERMS_OF_SERVICE', t('TERMS_OF_SERVICE')],
        ['PRIVACY_POLICY', t('PRIVACY_POLICY')],
        ['CONTRACT', t('CONTRACT')]
    ]);

    return TYPES;
};

export default PolicyDocumentTypes;