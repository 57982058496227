import Config from "../../../../../../../config";
import convertSize from "convert-size";
import icPdf from './../../../../../../../assets/svg/ic_pdf.svg';
import { useTranslation } from "react-i18next";

const InvitationUpload = ({invitation, fileInvitation, onChange}) => {
    const { t } = useTranslation();
    
    return (
        <div className="d-flex flex-row align-items-center bg-yellow-200 rounded-4 py-1 px-2 me-3">
            <a href={`${Config.URL_SERVER}${Config.PATH_PREFIX}${Config.PATH_INVITATIONS}${invitation}`} target="_blank" rel="noopener noreferrer">
                <img src={icPdf} alt="pdf" className="wh-20 me-1"/>
            </a>
            <div className="d-flex flex-column ms-1 me-2">
                <span className="fg-dark small-x">{t('INVITATION')}</span>
                {invitation && !fileInvitation && <span className="fg-green-500 small-x bold ms-2">{t('DOWNLOAD')}</span>}
                {fileInvitation && <span className="fg-green-500 small-x medium">{convertSize(fileInvitation.size, {accuracy: 3})}</span>}
            </div>
            <label htmlFor="uploadBtn1" onChange={onChange} className="upload-btn bg-white fg-dark">
                {t('UPLOAD')}
                <input id="uploadBtn1" accept="application/pdf" type="file" hidden/>
            </label>
        </div>
    )
}

export default InvitationUpload;