import { Link, useLocation } from "react-router-dom"

const SideBar = ({...props}) => {
    const location = useLocation();

    const isActive = (links) => {
        let flag = links.some((link) => {
            return (location.pathname === link);
        });

        return flag;
    }

    return (
        <div className="side-bar side-bar-settings" id="side-bar">
            { props.items.map((item) => {
                return(
                    <Link to={item.links[0]} className={"side-item"} key={item.id}>
                        <div className={"side-link " + (!isActive(item.links) && 'collapsed')} data-bs-target={`#${item.id}`} data-bs-toggle="collapse">
                            <img src={item.icon} className="wh-15 me-2"/>
                            <span>{item.name}</span>
                        </div>
                    </Link>
                )
            }) }
        </div>
    );
}

export default SideBar;