import {  useState } from "react";
import useAxiosPrivate from '../../../../../../../hooks/useAxiosPrivate';
import ModalU from '../../../../../components/ui/ModalU';
import Utils from "../../../../../../../utils";
import InputLabeled from '../../../../../components/form/InputLabeled';
import SelectLabeled1 from '../../../../../components/form/SelectLabeled';
import SelectLabeled2 from './components/SelectLabeled';
import { useTranslation } from "react-i18next";

const Create = ({...props}) => {
    const { t } = useTranslation();
    const axiosPrivate = useAxiosPrivate();
    
    const [documentTypeId, setDocumentTypeId] = useState('');
    const [additionalText, setAdditionalText] = useState('');
    const [serviceId, setServiceId] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        
        const requiredInputs = [
            { key: documentTypeId, value: t('DOCUMENT_TYPE_REQUIRED') },
            { key: serviceId, value: t('SERVICE_REQUIRED') },
        ]

        const content = Utils.errorHandling(requiredInputs);
        if (content) {
            props.setToast({ content, variant: 'danger', title: t('ERROR'), show: true });
            return;
        }

        props.setLoading(true);
        const data = { documentTypeId, additionalText, serviceId };
        axiosPrivate.post('/required-documents/create', data).then((res) => {
            if (res.status == 200) {
                props.setModalShow(false);
                props.setToast({ content: t('SUCCESS'), variant: 'success', title: t('SUCCESS'), show: true });
            }
        }).catch((err) => {
            if (!err?.response) props.setToast({ content: t('NO_SERVER_RESPONSE'), variant: 'danger', title: t('ERROR'), show: true });
            else props.setToast({ content: err.response.data.msg, variant: 'danger', title: t('ERROR'), show: true });
        }).finally(() => {props.setLoading(false)});
    }

    return (
        <ModalU show={props.modalShow} size={''} title={t('REQUIRED_DOCUMENT')}
            actionbtntext={t('SAVE')}
            actionbtnclassname={"bg-blue-700 font-14 medium text-white"}
            onSubmit={handleSubmit} onHide={props.setModalShow}>
                
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <SelectLabeled1 label={t('DOCUMENT_TYPE')} items={props.documentTypes} value={documentTypeId} setValue={setDocumentTypeId} required={true} className={'col-md-12 mt-2'}/>
                    <InputLabeled label={t('DESCRIPTION')} type={'text'} value={additionalText} setValue={setAdditionalText} hint={t('DESCRIPTION')} required={false}className={'col-md-12 mt-2'}/>
                    <SelectLabeled2 label={t('SERVICE')} items={props.services} value={serviceId} setValue={setServiceId} required={true} className={'col-md-12 mt-2'}/>
                </div>
            </form>
        </ModalU>
    );
}

export default Create