import {  useState } from "react";
import useAxiosPrivate from './../../../../../hooks/useAxiosPrivate';
import ModalU from './../../../../Admin/components/ui/ModalU';
import Utils from "../../../../../utils";
import InputLabeled from '../../../../Admin/components/form/InputLabeled';
import { useTranslation } from "react-i18next";
import icCreateUser from './../../../../../assets/svg/ic_create_user.svg';

const Create = ({...props}) => {
    const { t } = useTranslation();
    const axiosPrivate = useAxiosPrivate();
    
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [position, setPosition] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        
        const requiredInputs = [
            { key: email, value: t('EMAIL_REQUIRED') },
            { key: firstName, value: t('NAME_REQUIRED') },
            { key: lastName, value: t('LASTNAME_REQUIRED') },
            { key: phoneNumber, value: t('PHONE_NUMBER_REQUIRED') },
            { key: position, value: t('POSITION_REQUIRED') },
        ]

        const content = Utils.errorHandling(requiredInputs);
        if (content) {
            props.setToast({ content, variant: 'danger', title: t('ERROR'), show: true });
            return;
        }

        props.setLoading(true);
        const data = { email, firstName, lastName, phoneNumber, position };
        axiosPrivate.post('/employees/create', data).then((res) => {
            if (res.status == 200) {
                props.setModalShow(false);
                props.setToast({ content: t('SUCCESS'), variant: 'success', title: t('SUCCESS'), show: true });
            }
        }).catch((err) => {
            if (!err?.response) props.setToast({ content: t('NO_SERVER_RESPONSE'), variant: 'danger', title: t('ERROR'), show: true });
            else props.setToast({ content: err.response.data.msg, variant: 'danger', title: t('ERROR'), show: true });
        }).finally(() => {props.setLoading(false)});
    }

    return (
        <ModalU show={props.modalShow} size={''} title={t('EMPLOYEE')}
            actionbtntext={t('SAVE')}
            actionbtnclassname={"bg-blue-700 font-14 medium text-white"}
            onSubmit={handleSubmit} onHide={props.setModalShow}>
                
            <form onSubmit={handleSubmit} className="d-flex flex-column align-items-center">
                <img src={icCreateUser} />
                <span className="medium font-18 mt-3 mb-2">{t('EMPLOYEE_INFO')}</span>
                <div className="d-flex row mx-4">
                    <InputLabeled label={t('FIRSTNAME')} type={'text'} value={firstName} setValue={setFirstName} hint={t('FIRSTNAME')} required={true}/>
                    <InputLabeled label={t('LASTNAME')} type={'text'} value={lastName} setValue={setLastName} hint={t('LASTNAME')} required={true}/>
                    <InputLabeled label={t('EMAIL')} type={'text'} value={email} setValue={setEmail} hint={t('tkmvisa@gmail.com')} required={true}/>
                    <InputLabeled label={t('PHONE_NUMBER')} type={'number'} value={phoneNumber} setValue={setPhoneNumber} hint={t('+901234567890')} required={true}/>
                    <InputLabeled label={t('POSITION')} type={'text'} value={position} setValue={setPosition} hint={t('Admin')} required={true}/>
                </div>
            </form>
        </ModalU>
    );
}

export default Create