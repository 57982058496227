import { useRef, useState } from "react";
import { useNavigate} from 'react-router-dom';
import useAuth from "./../../../hooks/useAuth";
import AxiosHelper from "./../../../api/AxiosHelper";
import Keys from "../../../utils/constants";
import icForgotPassword from './../../../assets/svg/ic_forgot_password.svg';
import icEmail from './../../../assets/svg/ic_email.svg';
import InputLabelIcon from "../components/form/InputLabelIcon";
import { useTranslation} from 'react-i18next';

const SendEmailVerification = ({...props}) => {
    const navigate = useNavigate();
    const { auth, setAuth } = useAuth();
    const { t } = useTranslation();
    const emailRef = useRef();
    
    const [email, setEmail] = useState(''); 

    const handleSubmitEmail = (event) => {
        event.preventDefault();
        const v1 = Keys.EMAIL_REGEX.test(email);

        if (!v1) {
            props.setToast({ content: t('INVALID_ENTRY'), variant: 'danger', title: t('ERROR'), show: true });
            return;
        }
        
        const data = { email };
        AxiosHelper.sendEmailVerification(data).then((res) => {
            if (res.status == 200) {
                setAuth({...auth, user: { email }});
                props.setToast({ content: t('VERIFY_YOUR_EMAIL'), variant: 'primary', title: t('SUCCESS'), show: true });
                props.setStep(2);
            }
        }).catch((err) => {
            if (!err?.response) {
                props.setToast({ content: t('NO_SERVER_RESPONSE'), variant: 'danger', title: t('ERROR'), show: true });
                return;
            }
            switch(err.response.status) {
                case 401: props.setToast({ content: t('ACCOUNT_NOT_VERIFIED'), variant: 'danger', title: t('UNAUTHORIZED'), show: true }); break;
                case 404: props.setToast({ content: t('USER_NOT_FOUND'), variant: 'warning', title: t('NOT_FOUND'), show: true }); break;
                default : props.setToast({ content: err.response.data.msg, variant: 'danger', title: t('ERROR'), show: true }); break;
            }
        });
    }

    return (
        <>
            <img src={icForgotPassword} />
            <span className="text-center fs-4 medium fg-dark mt-2">{t('RESET_PASSWORD')}</span>
            <span className="text-center font-14 fg-gray mt-2">{t('ENTER_EMAIL_FOR_RESTORING_PASSWORD')}</span>
            <div className="container col-md-3 mt-4">
                <form onSubmit={handleSubmitEmail} noValidate>
                    <InputLabelIcon 
                        formClass="" inputClass={'col-md-12'}
                        id={'email'} type={'email'} ref={emailRef}
                        value={email} setValue={setEmail}
                        hint={t('EMAIL')} autoFocus={true} required={true}
                        label={t('EMAIL')} labelClass={'fg-dark'} icon={icEmail}
                    />
                    <button type="submit" className="button-u bg-sp-primary col-12 my-3" disabled={(!email)}>
                        {t('CONFIRM')}
                    </button>
                    <button onClick={() => navigate(-1)} className="button-u fg-dark col-12 my-3">
                        {t('BACK')}
                    </button>
                </form>
            </div>
        </>
    );
}

export default SendEmailVerification;