import { useState } from 'react';
import icPdf from './../../../../../../../assets/svg/ic_pdf.svg';
import Config from "../../../../../../../config";
import { useTranslation } from "react-i18next";
import UpdateReceipt from './UpdateReceipt';

const Receipt = ({item, index, toast, setToast, setLoading}) => {
    const { t } = useTranslation();

    const [itemToBe, setItemToBe] = useState({});

    const [modalUpdate, setModalUpdateReceipt] = useState(false);

    return (
        <div className="col-md-3">
            <div className="d-flex flex-column justify-content-between bg-light border border-gray rounded-3 my-1 py-2 px-3">
                <div className="d-flex flex-column">
                    <span className="fg-dark font-12">{`${t('PAYMENT')}: ${index + 1}`}</span>
                    <span className="fg-gray font-12">{`${t('NOTE')}: ${item.note}`}</span>
                </div>
                <div className="d-flex flex-row align-items-center justify-content-between bg-white border border-gray rounded-2 mt-2 py-1 px-1">
                    <a href={`${Config.URL_SERVER}${Config.PATH_PREFIX}${Config.PATH_RECEIPTS}${item.receipt}`} target="_blank" rel="noopener noreferrer">
                        <img src={icPdf} alt="pdf" className="wh-20 me-1"/>
                    </a>
                    <span className='small text-primary ms-1 me-2 hover-cursor'
                        onClick={() => {
                            setModalUpdateReceipt(true)
                            setItemToBe(item);
                        }}>
                        {t('EDIT')}
                    </span>
                </div>
            </div>
            <UpdateReceipt 
                modalShow={modalUpdate}
                setModalShow={setModalUpdateReceipt}
                item={itemToBe}
                toast={toast}
                setToast={setToast}
                setLoading={setLoading}
            />
        </div>
    )
}

export default Receipt;