import DataWrapper from "../DataWrapper";
import Option from "./../../../components/Option";
import icFile from "../../../../../../assets/svg/ic_file.svg";
import icMap from "../../../../../../assets/svg/ic_map.svg";
import icPassport from "../../../../../../assets/svg/ic_passport.svg";
import icCalendar from "../../../../../../assets/svg/ic_calendar.svg";
import icSortByTime from "../../../../../../assets/svg/ic_sort_by_time.svg";
import icDocumentAdd from "../../../../../../assets/svg/ic_document_add.svg";
import { useTranslation } from "react-i18next";
import Config from "../../../../../../config";

const Services = () => {
    const { t } = useTranslation();

    const breadcrumbs = [
        { title: t('SETTINGS'), link: `${Config.ADMIN_PREFIX}/settings/profile`}, { title: t('DATABASE'), link: `${Config.ADMIN_PREFIX}/settings/data/general`}, { title: t('SERVICES'), active: true }
    ]

    const options = [
        { id: 'document_types', name: t('DOCUMENT_TYPES'), icon: icFile, link: `${Config.ADMIN_PREFIX}/settings/data/services/document-types`},
        { id: 'countries', name: t('COUNTRIES'), icon: icMap, link: `${Config.ADMIN_PREFIX}/settings/data/services/countries`},
        { id: 'visa_types', name: t('VISA_TYPES'), icon: icPassport, link: `${Config.ADMIN_PREFIX}/settings/data/services/visa-types`},
        { id: 'term_types', name: t('TERM_TYPES'), icon: icCalendar, link: `${Config.ADMIN_PREFIX}/settings/data/services/term-types`},
        { id: 'preparation_times', name: t('PREPARATION_TIMES'), icon: icSortByTime, link: `${Config.ADMIN_PREFIX}/settings/data/services/preparation-times`},
        { id: 'required_documents', name: t('REQUIRED_DOCUMENTS'), icon: icDocumentAdd, link: `${Config.ADMIN_PREFIX}/settings/data/services/required-documents`},
    ]

    return (
        <DataWrapper breadcrumbs={breadcrumbs}>
            <div className="row p-3">
                { options.map((option) => {
                    return (<Option key={option.id} item={option}/>)
                })}
            </div>
        </DataWrapper>
    );
}

export default Services;