import {  useState } from "react";
import useAxiosPrivate from './../../../../../../../hooks/useAxiosPrivate';
import ModalU from './../../../../../../Admin/components/ui/ModalU';
import Utils from "./../../../../../../../utils";
import InputLabeled from './../../../../../../Admin/components/form/InputLabeled';
import { useTranslation } from "react-i18next";

const Create = ({...props}) => {
    const { t } = useTranslation();
    const axiosPrivate = useAxiosPrivate();
    
    const [name, setName] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        
        const requiredInputs = [
            { key: name, value: t('NAME_REQUIRED') },
        ]

        const content = Utils.errorHandling(requiredInputs);
        if (content) {
            props.setToast({ content, variant: 'danger', title: t('ERROR'), show: true });
            return;
        }

        props.setLoading(true);
        const data = { name };
        axiosPrivate.post('/document-types/create', data).then((res) => {
            if (res.status == 200) {
                props.setModalShow(false);
                props.setToast({ content: t('SUCCESS'), variant: 'success', title: t('SUCCESS'), show: true });
            }
        }).catch((err) => {
            if (!err?.response) props.setToast({ content: t('NO_SERVER_RESPONSE'), variant: 'danger', title: t('ERROR'), show: true });
            else props.setToast({ content: err.response.data.msg, variant: 'danger', title: t('ERROR'), show: true });
        }).finally(() => {props.setLoading(false)});
    }

    return (
        <ModalU show={props.modalShow} size={''} title={t('DOCUMENT_TYPE')}
            actionbtntext={t('ADD')}
            actionbtnclassname={"bg-blue-700 font-14 medium text-white"}
            onSubmit={handleSubmit} onHide={props.setModalShow}>
                
            <form onSubmit={handleSubmit}>
                <InputLabeled label={t('NAME')} type={'text'} value={name} setValue={setName} hint={t('NAME')} required={true}/>
            </form>
        </ModalU>
    );
}

export default Create