import {  useState } from "react";
import useAxiosPrivate from '../../../../../../../hooks/useAxiosPrivate';
import ModalU from '../../../../../components/ui/ModalU';
import Utils from "../../../../../../../utils";
import Config from './../../../../../../../config';
import InputLabeled from '../../../../../components/form/InputLabeled';
import InputAreaLabeled from '../../../../../components/form/InputAreaLabeled';
import { useTranslation } from "react-i18next";

const Create = ({...props}) => {
    const { t } = useTranslation();
    const axiosPrivate = useAxiosPrivate();
    
    const [name, setName] = useState('');
    const [code, setCode] = useState('');
    const [flag, setFlag] = useState('');
    const [banner, setBanner] = useState('');
    const [fileFlag, setFileFlag] = useState();
    const [fileBanner, setFileBanner] = useState();
    const [description, setDescription] = useState('');

    const handleFileFlag = (e) => {
        setFlag(URL.createObjectURL(e.target.files[0]));
        setFileFlag(e.target.files[0]);
    }

    const handleFileBanner = (e) => {
        setBanner(URL.createObjectURL(e.target.files[0]));
        setFileBanner(e.target.files[0]);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        
        const requiredInputs = [
            { key: name, value: t('NAME_REQUIRED') },
            { key: code, value: t('CODE_REQUIRED') },
            { key: flag, value: t('FLAG_REQUIRED') },
            { key: banner, value: t('BANNER_REQUIRED') },
            { key: description, value: t('DESCRIPTION_REQUIRED') },
        ]

        const content = Utils.errorHandling(requiredInputs);
        if (content) {
            props.setToast({ content, variant: 'danger', title: t('ERROR'), show: true });
            return;
        }

        props.setLoading(true);
        const data = { name, code, description, flag: fileFlag, banner: fileBanner };
        
        const headers = { 'Content-Type': 'multipart/form-data'};
        axiosPrivate.post('/countries/create', data, {headers}).then((res) => {
            if (res.status == 200) {
                props.setModalShow(false);
                props.setToast({ content: t('SUCCESS'), variant: 'success', title: t('SUCCESS'), show: true });
            }
        }).catch((err) => {
            if (!err?.response) props.setToast({ content: t('NO_SERVER_RESPONSE'), variant: 'danger', title: t('ERROR'), show: true });
            else props.setToast({ content: err.response.data.msg, variant: 'danger', title: t('ERROR'), show: true });
        }).finally(() => {props.setLoading(false)});
    }

    return (
        <ModalU show={props.modalShow} size={'lg'} title={t('COUNTRY')}
            actionbtntext={t('ADD')}
            actionbtnclassname={"bg-blue-700 font-14 medium text-white"}
            onSubmit={handleSubmit} onHide={props.setModalShow}>
                
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className='d-flex flex-row col-md-5 mb-3'>
                        <img src={flag} className="country-flag"/>
                        <div className="d-flex flex-column ms-3">
                            <span className="fg-dark">{t('FLAG')}</span>
                            <span className="fg-gray font-12">{t('LOGO_FORMAT_SIZE_DESC')}</span>
                            <div>
                                <label htmlFor="uploadBtn1" onChange={handleFileFlag} className="upload-btn bg-blue-600 text-white mt-3">
                                    {t('UPLOAD')}
                                    <input id="uploadBtn1" accept="image/png" type="file" hidden/>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="row col-md-7">
                        <InputLabeled label={t('NAME')} type={'text'} value={name} setValue={setName} hint={'Turkmenistan'} className={'col-md-8'} required={true}/>
                        <InputLabeled label={t('CODE')} type={'text'} value={code} setValue={setCode} hint={'TM'} className={'col-md-4'} required={true}/>
                    </div>
                    <div className='d-flex flex-row col-md-6 my-3'>
                        <img src={banner} className="wh-150 border rounded-2"/>
                        <div className="d-flex flex-column ms-3">
                            <span className="fg-dark">{t('BANNER')}</span>
                            <span className="fg-gray font-12">{t('LOGO_FORMAT_SIZE_DESC')}</span>
                            <div>
                                <label htmlFor="uploadBtn2" onChange={handleFileBanner} className="upload-btn bg-blue-600 text-white mt-3">
                                    {t('UPLOAD')}
                                    <input id="uploadBtn2" accept="image/png" type="file" hidden/>
                                </label>
                            </div>
                        </div>
                    </div>
                    <InputAreaLabeled label={t('DESCRIPTION')} type={'text'} rows={8} value={description} setValue={setDescription} hint={t('DESCRIPTION')} className={'col-md-12'} required={true}/>
                </div>
            </form>
        </ModalU>
    );
}

export default Create