import { useState } from "react";
import useAuth from "./../../hooks/useAuth";
import UserAvatar from "./../../pages/Admin/components/ui/UserAvatar";
import DropdownLang from "./../DropdownLang";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import BreadCrumbsU from "./../../pages/Admin/components/ui/BreadCrumbsU";
import icHome from "./../../assets/svg/ic_home.svg";
import LinearIndeterminant from "./../../pages/Admin/components/ui/PbLinearIndeterminant";
import ProgressBar from "./../../pages/Admin/components/ui/ProgressBar";
import RightSideModal from "./../../pages/Admin/components/ui/RightSideModal";
import Config from "../../config";
import { Drawer } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

const Header = ({...props}) => {
    const { auth } = useAuth();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [open, setOpen] = useState(false);

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
      };

    return (
        <div className="header">
            <div className="d-flex flex-row align-items-center justify-content-between px-3">
                <div className="d-flex flex-row px-1 align-items-center">
                    <div className="d-flex flex-row align-items-center me-3">
                        {!props.rootPage && 
                            <div className="d-flex flex-row align-items-center">
                                <FontAwesomeIcon onClick={() => navigate(-1)} className="wh-15 rounded-circle p-2 bg-gray-50 me-2 hover-cursor" icon={faChevronLeft}/>
                                <img src={icHome} onClick={() => navigate(Config.ADMIN_PREFIX)} className="wh-35 px-1 hover-cursor rounded-2 btn-hover"/>
                            </div>
                        }
                        <BreadCrumbsU items={props.breadcrumbs} className={'pt-1 ms-2'}/>
                    </div>
                </div>
                <div className="d-flex flex-row align-items-center justify-content-center">
                    <DropdownLang/>
                    <div className="d-flex flex-row align-items-center hover-cursor ms-3 me-1"
                        onClick={toggleDrawer(true)}>
                        <UserAvatar user={auth.user} style={'bg-green-600 text-white wh-35'}/>
                    </div>
                </div>
            </div>
            <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
                <RightSideModal onClose={toggleDrawer(false)}/>
            </Drawer>
            {props.loading && <LinearIndeterminant/>}
            {props.loading && <ProgressBar loading={props.loading}/>}
        </div>
    );
}

export default Header;