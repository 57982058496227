import React from "react";
import { Dropdown } from "react-bootstrap";

const DropdownU2 = ({...props}) => {
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <div
          className={props.toggleClass}
          ref={ref}
          onClick={(e) => {
            e.preventDefault();
            onClick(e);
          }}
          >
          <img src={props.icon} className="wh-30"/>
        </div>
    ));

    return (
        <Dropdown className={`small hover-cursor ${props.className}`}>
            <Dropdown.Toggle as={CustomToggle}></Dropdown.Toggle>
            <Dropdown.Menu>
                { props.items.map((item) => {
                    return (
                        <Dropdown.Item className="d-flex align-items-center px-3 py-1" onClick={item.onClick} key={item.name}>
                            {item.icon && <img src={item.icon} className="wh-30"/>}
                            <div className="me-2"></div>
                            <span className={item.color}>{item.name}</span>
                        </Dropdown.Item>
                    )
                })}
            </Dropdown.Menu>
        </Dropdown>
    );
}

export default DropdownU2;