import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Option = ({...props}) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const item = props.item;

    return (
        <div onClick={() => navigate(item.link)} className="col-md-3 btn-hover my-2">
            <div className="d-flex flex-column justify-content-between bg-white rounded-2 border border-light shadow-sm btn-hover" style={{height: '150px'}}>
                <div className="d-flex flex-row align-items-center mt-3 mx-3">
                    <img src={item.icon} alt={item.name} className="wh-30"/>
                    <span className="medium fg-dark ms-2">{item.name}</span>
                </div>
                <span className="fg-gray font-12 my-2 mx-3">{item.name}</span>
                <div className="d-flex flex-column">
                    <hr className="m-0 fg-gray-50"/>
                    <div className="d-flex flex-row justify-content-between mx-3 my-2">
                        <span></span>
                        <span className="no-decor font-12 text-primary medium">{t('SHOW')}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Option;