import { useEffect, useState } from "react";
import useAxiosPrivate from './../../../../../../../hooks/useAxiosPrivate';
import Config from "../../../../../../../config";
import ModalU from './../../../../../../Admin/components/ui/ModalU';
import Utils from "./../../../../../../../utils";
import InputLabeled from './../../../../../../Admin/components/form/InputLabeled';
import { useTranslation } from "react-i18next";

const Update = ({...props}) => {
    const { t } = useTranslation();
    const axiosPrivate = useAxiosPrivate();
    const item = props.item;
    
    const [name, setName] = useState('');
    const [logo, setLogo] = useState('');
    const [file, setFile] = useState();

    const handleFile = (e) => {
        setLogo(URL.createObjectURL(e.target.files[0]));
        setFile(e.target.files[0]);
    }

    useEffect(() => {
        setName(item.name);
        setLogo(item.logo);
    }, [item])

    const handleSubmit = (event) => {
        event.preventDefault();
        
        const requiredInputs = [
            { key: name, value: t('VALUE_REQUIRED') },
            { key: (file || logo), value: t('LOGO_REQUIRED') },
        ]

        const content = Utils.errorHandling(requiredInputs);
        if (content) {
            props.setToast({ content, variant: 'danger', title: t('ERROR'), show: true });
            return;
        }

        props.setLoading(true);
        const data = { name, path: Config.PATH_BANKS, filename: item.logo, file };
        
        const headers = { 'Content-Type': 'multipart/form-data'};
        axiosPrivate.put('/banks/update/'+item.id, data, {headers}).then((res) => {
            if (res.status == 200) {
                props.setModalShow(false);
                props.setToast({ content: t('SUCCESS'), variant: 'success', title: t('SUCCESS'), show: true });
            }
        }).catch((err) => {
            if (!err?.response) props.setToast({ content: t('NO_SERVER_RESPONSE'), variant: 'danger', title: t('ERROR'), show: true });
            else props.setToast({ content: err.response.data.msg, variant: 'danger', title: t('ERROR'), show: true });
        }).finally(() => {props.setLoading(false)});
    }

    return (
        <ModalU show={props.modalShow} size={''} title={`${t('BANK')} [${t('EDIT')}]`}
            actionbtntext={t('UPDATE')}
            actionbtnclassname={"bg-green-700 font-14 medium text-white"}
            onSubmit={handleSubmit} onHide={props.setModalShow}>
                
            <form onSubmit={handleSubmit}>
                <div className="d-flex flex-column">
                    <div className='d-flex flex-row mb-3'>
                        { logo && !file ?
                            <img src={`${Config.URL_SERVER}${Config.PATH_PREFIX}${Config.PATH_BANKS}${item.logo}`} className="bank-logo"/>
                            :
                            <img src={logo} className="bank-logo mt-2"/>
                        }
                        <div className="d-flex flex-column ms-3">
                            <span className="fg-dark">{t('LOGO')}</span>
                            <span className="fg-gray font-12">{t('LOGO_FORMAT_SIZE_DESC')}</span>
                            <div>
                                <label htmlFor="uploadBtn" onChange={handleFile} className="upload-btn bg-blue-600 text-white mt-3">
                                    {t('UPLOAD')}
                                    <input id="uploadBtn" accept="image/png" type="file" hidden/>
                                </label>
                            </div>
                        </div>
                    </div>
                    <InputLabeled label={t('NAME')} type={'text'} value={name} setValue={setName} hint={t('NAME')} required={true}/>
                </div>
            </form>
        </ModalU>
    );
}

export default Update