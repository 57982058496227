import { useEffect, useRef, useState } from "react";
import useAuth from "./../../../hooks/useAuth";
import { useNavigate, Link } from 'react-router-dom';
import AxiosHelper from "./../../../api/AxiosHelper";
import ToastU from "./../components/ui/ToastU";
import Wrapper from "./Wrapper";
import Config from "../../../config";
import Keys from "../../../utils/constants";
import icLoginUser from './../../../assets/svg/ic_login_user.svg';
import icEmail from './../../../assets/svg/ic_email.svg';
import icLock from './../../../assets/svg/ic_lock.svg';
import icEyeOpen from './../../../assets/svg/ic_eye_open.svg';
import InputLabelIcon from "../components/form/InputLabelIcon";
import VerifyOtp from "./VerifyOtp";
import illSuccess from './../../../assets/svg/illustrations/ill_success.svg';
import { useTranslation} from 'react-i18next';

const Login = () => {
    const navigate = useNavigate();
    const { auth, setAuth, ready } = useAuth();
    const { t } = useTranslation();
   
    const [step, setStep] = useState(1);
   
    const emailRef = useRef();
    
    const [email, setEmail] = useState(''); 
    const [password, setPassword] = useState('');

    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    useEffect(() => {
        if (ready && !auth.token) emailRef.current.focus();
    }, [ready]);

    useEffect(() => {
        if (auth.token == undefined && !auth.token) return;
        if (ready) navigate(Config.ADMIN_PREFIX);
    }, [auth]);

    const handleLoginSubmit = (event) => {
        event.preventDefault();

        const data = { email, password }
        AxiosHelper.login(data).then((res) => {
            if (res.status == 200) {
                setAuth(res.data);
                navigate(Config.ADMIN_PREFIX);
            } else if (res.status == 201) {
                setAuth(res.data);
                setToast({ content: t('ENTER_VERIF_CODE_THAT_SENT_EMAIL'), variant: 'primary', title: t('SUCCESS'), show: true });
                setStep(2);
            }
        }).catch((err) => {
            if (!err?.response) {
                setToast({ content: t('NO_SERVER_RESPONSE'), variant: 'danger', title: t('ERROR'), show: true });
                return;
            }
            switch(err.response.status) {
                case 400: setToast({ content: t('EMAIL_OR_PWD_NOT_ENTERED'), variant: 'danger', title: t('INVALID_ENTRY'), show: true }); break;
                case 402: setToast({ content: t('SIGN_IN_FAILED'), variant: 'danger', title: t('UNSUCCESS'), show: true }); break;
                case 404: setToast({ content: t('USER_NOT_FOUND'), variant: 'warning', title: t('NOT_FOUND'), show: true }); break;
                case 405: setToast({ content: t('USER_IS_NOT_ADMIN'), variant: 'danger', title: t('NOT_ALLOWED'), show: true }); break;
                default : setToast({ content: err.response.data.msg, variant: 'danger', title: t('ERROR'), show: true }); break;
            }
        });
    }

    return (
        (!ready && auth.token == Keys.UNAUTHORIZED) && 
        <Wrapper>
            {step == 1 ?
            <>
                <img src={icLoginUser} />
                <span className="text-center fs-4 medium fg-dark mt-2">{t('SIGN_IN')}</span>
                <span className="text-center font-14 fg-dark mt-2">{t('ENTER_LOGIN_INFO')}</span>
                <div className="container col-md-3 mt-4">
                    <form onSubmit={handleLoginSubmit} noValidate>
                        <InputLabelIcon 
                            formClass="" inputClass={'col-md-12'}
                            id={'email'} type={'email'} ref={emailRef}
                            value={email} setValue={setEmail}
                            hint={t('EMAIL')} autoFocus={true} required={true}
                            label={t('EMAIL')} labelClass={'fg-dark'} icon={icEmail}
                        />
                        <InputLabelIcon 
                            formClass={'mt-3 mb-2'} inputClass={'col-md-12'}
                            id={'password'} type={'password'} ref={null}
                            value={password} setValue={setPassword}
                            hint={t('YOUR_PASSWORD')} autoFocus={false} required={true}
                            label={t('PASSWORD')} labelClass={'fg-dark'} icon={icLock} iconRight={icEyeOpen}
                        />
                        <Link to={`${Config.ADMIN_PREFIX}/auth/reset-password`} className="font-13 fg-dark">{t('FORGOT_PASSWORD')}</Link>
                        <button type="submit"
                            className="button-u bg-sp-primary col-12 my-3"
                            disabled={(!email || !password)}>
                            {t('SIGN_IN')}
                        </button>
                        {/* <div className="d-flex flex-column">
                            <Link to={`${Config.ADMIN_PREFIX}/auth/signup`} className="medium fg-dark no-decor font-14 col-12 text-center">{t('SIGN_UP')}</Link>
                        </div> */}
                    </form>
                </div>
            </>
            : step === 2 ? 
            <VerifyOtp toast={toast} setToast={setToast} setStep={setStep}/>
            :
            <>
                <img src={illSuccess} />
                <span className="fg-dark fs-4 medium">{t('SUCCESS')}</span>
                <button onClick={() => setStep(1)} className="button-u bg-sp-primary col-3 my-4">
                    {t('SIGN_IN')}
                </button>
            </>
            }
            <ToastU toast={toast} setToast={setToast}/>    
        </Wrapper>
    );
};

export default Login;