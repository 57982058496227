import { useEffect, useState } from "react";
import useAxiosPrivate from './../../../../../../../hooks/useAxiosPrivate';
import ModalU from './../../../../../../Admin/components/ui/ModalU';
import Utils from "./../../../../../../../utils";
import InputLabeled from './../../../../../../Admin/components/form/InputLabeled';
import SelectLabeled from '../../../../../components/form/SelectLabeled';
import { useTranslation } from "react-i18next";

const Update = ({...props}) => {
    const { t } = useTranslation();
    const axiosPrivate = useAxiosPrivate();
    const item = props.item;
    
    const [bankId, setBankId] = useState('');
    const [currencyId, setCurrencyId] = useState('');
    const [key, setKey] = useState('');
    const [value, setValue] = useState('');
    const [owner, setOwner] = useState('');

    useEffect(() => {
        setBankId(item.bankId);
        setCurrencyId(item.currencyId);
        setKey(item.key);
        setValue(item.value);
        setOwner(item.owner);
    }, [item])

    const handleSubmit = (event) => {
        event.preventDefault();
        
        const requiredInputs = [
            { key: bankId, value: t('BANK_REQUIRED') },
            { key: currencyId, value: t('CURRENCY_REQUIRED') },
            { key: key, value: t('KEY_REQUIRED') },
            { key: value, value: t('VALUE_REQUIRED') },
            { key: owner, value: t('OWNER_REQUIRED') },
        ]

        const content = Utils.errorHandling(requiredInputs);
        if (content) {
            props.setToast({ content, variant: 'danger', title: t('ERROR'), show: true });
            return;
        }

        props.setLoading(true);
        const data = { bankId, currencyId, key, value, owner };
        
        axiosPrivate.put('/bank-accounts/update/'+item.id, data ).then((res) => {
            if (res.status == 200) {
                props.setModalShow(false);
                props.setToast({ content: t('SUCCESS'), variant: 'success', title: t('SUCCESS'), show: true });
            }
        }).catch((err) => {
            if (!err?.response) props.setToast({ content: t('NO_SERVER_RESPONSE'), variant: 'danger', title: t('ERROR'), show: true });
            else props.setToast({ content: err.response.data.msg, variant: 'danger', title: t('ERROR'), show: true });
        }).finally(() => {props.setLoading(false)});
    }

    return (
        <ModalU show={props.modalShow} size={''} title={`${t('BANK_ACCOUNT')} [${t('EDIT')}]`}
            actionbtntext={t('UPDATE')}
            actionbtnclassname={"bg-green-700 font-14 medium text-white"}
            onSubmit={handleSubmit} onHide={props.setModalShow}>
                
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <SelectLabeled label={t('BANK')} items={props.banks} value={bankId} setValue={setBankId} required={true} className={'col-md-6'}/>
                    <SelectLabeled label={t('CURRENCY')} items={props.currencies} value={currencyId} setValue={setCurrencyId} required={true} className={'col-md-6'}/>
                    <InputLabeled label={t('KEY')} type={'text'} value={key} setValue={setKey} hint={'IBAN, CARD-NO, etc...'} required={true} className={'col-md-12'}/>
                    <InputLabeled label={t('VALUE')} type={'text'} value={value} setValue={setValue} hint={'TR 00 0001 0102 ...'} required={true} className={'col-md-12'}/>
                    <InputLabeled label={t('OWNER')} type={'text'} value={owner} setValue={setOwner} hint={t('FULLNAME')} required={true} className={'col-md-12'}/>
                </div>
            </form>
        </ModalU>
    );
}

export default Update