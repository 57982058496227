import ModalU from '../../../components/ui/ModalU';
import { useTranslation } from "react-i18next";

const Show = ({...props}) => {
    const { t } = useTranslation();
    const item = props.item;

    return (
        <ModalU show={props.modalShow} size={''} title={t('SUPPORT_REQUEST')}
            onHide={props.setModalShow}>
            <div className="d-flex flex-column">
                <span className="fg-dark medium">{t('USER')}: {item?.user?.fullName}</span>
                <span className="fg-dark medium">{t('PHONE_NUMBER')}: {item?.phoneNumber}</span>
                <div className="d-flex flex-column mt-3">
                    <span className="medium fg-dark font-14">{t('SUBJECT')}</span>
                    <span className="fg-dark font-14">{item?.subject}</span>
                </div>
                <div className="d-flex flex-column mt-3">
                    <span className="medium fg-dark font-14">{t('MESSAGE')}</span>
                    <span className="fg-dark font-14">{item?.content}</span>
                </div>
            </div>
        </ModalU>
    );
}

export default Show