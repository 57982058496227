import { useState, useEffect } from "react";
import { Link, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import useAuth from "../../../../../hooks/useAuth";
import useAxiosPrivate from '../../../../../hooks/useAxiosPrivate';
import { useNavigate } from "react-router-dom";
import { faAdd, faSearch, faArrowRotateRight } from "@fortawesome/free-solid-svg-icons";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import UserAvatar from "./../../../../../pages/Admin/components/ui/UserAvatar";
import Utils from "../../../../../utils";
import MainWrapper from './../../layouts/MainWrapper';
import InputField from "../../../../Admin/components/form/InputField";
import TableStd from '../../../../Admin/components/ui/TableStd';
import Filter from "./Filter";
import PaginationU from '../../../../Admin/components/ui/PaginationU';
import LimitU from '../../../../Admin/components/ui/LimitU';
import DataNotFound from '../../../../Admin/components/ui/DataNotFound';
import ButtonU from "../../../../Admin/components/ui/ButtonU";
import ToastU from "../../../../Admin/components/ui/ToastU";
import SortU from "../../../../Admin/components/ui/SortU";
import Status from "../../../../Admin/components/ui/Status";
import { useTranslation } from "react-i18next";
import Config from "../../../../../config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Users = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const { ready } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [users, setUsers] = useState([]);
    const [usersCount, setUsersCount] = useState();

    const parsed = queryString.parse(location.search);
    const urlQuery = {
        page: parsed.page || 1,
        limit: 10,
        order: parsed.order || [['createdAt', 'DESC']],
        filter: { createdAtLb: '', createdAtUb: '' }
    }
    const [query, setQuery] = useState(urlQuery);

    const [find, setfind] = useState('');
    
    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    const breadcrumbs = [
        { title: t('MAIN'), link: `${Config.ADMIN_PREFIX}/`}, { title: t('USERS'), active: true }
    ]

    useEffect(() => {
        if (ready) {
            getUpdatedUrl();
            getUsers();
        }
    }, [ready, query, toast]);

    const getUpdatedUrl = () => {
        parsed.page = query.page;
        window.history.replaceState({}, '', `${location.pathname}?${queryString.stringify(parsed)}`);
    }

    const getUsers = () => {
        setLoading(true);
        axiosPrivate.get('/users', { params: query }).then((res) => {
            if (res.status == 200) {
                setUsers(res.data.data);
                setUsersCount(res.data.count);
            }
        }).finally(() => {setLoading(false)});
    }

    useEffect(() => {
        setQuery({...query, filter: { name: find }});
    }, [find]);
 
    const createNew = () => { navigate(`${Config.ADMIN_PREFIX}/auth/signup`)}

    const refresh = () => { getUsers() }
    
    const modelProps = [
        { 
            name: <SortU title={t('USER')} attr={'email'} query={query} setQuery={setQuery}/>,
            value: (prop) => {
                return(
                    <div className="d-flex flex-row align-items-center">
                        <UserAvatar user={prop} style={'bg-blue-100 bg-gradient fg-blue-500 wh-30'}/>
                        <div className="d-flex flex-column ms-2">
                            <span className="font-13">{prop.fullName}</span>
                            <span className="small medium">{prop.email}</span>
                        </div>
                    </div>
                )
            } 
        },
        {
            name: <SortU title={t('PHONE_NUMBER')} attr={'phoneNumber'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{prop.phoneNumber ? prop.phoneNumber : '-'}</>) } 
        },
        {
            name: t('VERIFIED'),
            value: (prop) => { return(<Status status={prop.userAuth.verified}/>) }
        },
        {
            name: <SortU title={t('CREATED_DATE')} attr={'createdAt'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{Utils.getTimestampDateHm(prop.createdAt)}</>) }
        },
        {
            name: "",
            value: (prop) => {
                return (
                    <Link to={`${Config.ADMIN_PREFIX}/users/${prop.id}`}>
                        <FontAwesomeIcon icon={faEye} className="fg-dark font-14 bg-neutral rounded-circle p-2"/>
                    </Link>
                )
            }
        }
    ];

    return (
        <MainWrapper loading={loading} breadcrumbs={breadcrumbs}>
            <div className="head-long-page">
                <span className="bold fs-5 fg-dark">{t('USERS')}</span>
                <div className="d-flex align-items-center">
                    <ButtonU onSubmit={refresh} className={'border-dark fg-dark'} icon={faArrowRotateRight}/>
                    <InputField type={"text"} value={find} setValue={setfind} hint={`${t('NAME')}, ${t('LASTNAME')}, ${t('EMAIL')}`} icon={faSearch} formClass="input-field-container me-1 font-14" inputClass="input-field-outline"/> 
                    <Filter query={query} setQuery={setQuery} />
                    <ButtonU onSubmit={createNew} className={'border-success fg-green-500'} icon={faAdd} text={t('ADD')}/>
                </div>
            </div>
            { usersCount ?
                <div className="mt-5">
                    <TableStd modelProps={modelProps} data={users} count={usersCount} query={query} bgHead={'bg-light'}/>
                    <div className='d-flex align-items-center justify-content-between'>
                        <LimitU size={"sm"} filter={query} setFilter={setQuery}/>
                        <PaginationU filter={query} setFilter={setQuery} count={usersCount} />
                    </div>
                </div>
                : <DataNotFound/>
            }
            <ToastU toast={toast} setToast={setToast}/>
            <div className="mb-5"></div>
        </MainWrapper>
    );
}

export default Users;