import convertSize from "convert-size";
import icPdf from './../../../../../../../assets/svg/ic_pdf.svg';
import Config from "../../../../../../../config";

const RequiredDocument = ({item, onChange}) => {
    return (
        <div className="col-md-4">
            <div className="d-flex flex-column bg-light border border-gray rounded-3 my-1 py-2 px-3">
                <div className="d-flex flex-column">
                    <div className="d-flex flex-row align-items-center">
                        <span className="fg-dark font-12">{item.requiredDocument?.documentType?.name}</span>
                        {item.document && !item.file && <span className="fg-green-500 small medium ms-2">{convertSize(item.size, {accuracy: 3})}</span>}
                        {item.file && <span className="fg-green-500 small medium ms-2">{convertSize(item.file.size, {accuracy: 3})}</span>}
                    </div>
                    <span className="fg-gray small-x">{item.requiredDocument.additionalText ? item.requiredDocument.additionalText : '-'}</span>
                </div>
                <div className="d-flex flex-row align-items-center bg-white border border-gray rounded-2 mt-2 py-1 px-1">
                    <a href={`${Config.URL_SERVER}${Config.PATH_PREFIX}${Config.PATH_APPOINTMENT_DOCS}${item.document}`} target="_blank" rel="noopener noreferrer">
                        <img src={icPdf} alt="pdf" className="wh-20 me-1"/>
                    </a>
                    <input id="uploadBtn" type="file" accept="application/pdf" className="small-x" onChange={onChange}/>
                </div>
            </div>
        </div>
    )
}

export default RequiredDocument;