import React, { useEffect, useState, useRef } from "react";
import useAuth from "./../../../hooks/useAuth";
import { useNavigate } from 'react-router-dom';
import AxiosHelper from "./../../../api/AxiosHelper";
import Keys from "./../../../utils/constants";
import icEmailVerification from './../../../assets/svg/ic_email_verification.svg';
import InputOtpCode from "../components/form/InputOtpCode";
import { useTranslation} from 'react-i18next';

const VerifyOtp = ({...props}) => {
    const navigate = useNavigate();
    const { auth } = useAuth();
    const { t } = useTranslation();
    const inputRefs = useRef([]);
    
    const [code, setCode] = useState(new Array(5).fill(""));

    const [minutes, setMinutes] = useState(3);
    const [seconds, setSeconds] = useState(0);

    useEffect(() => {
        inputRefs.current = Array(code.length).fill().map((_, i) => inputRefs.current[i] || React.createRef());
    }, [code.length]);

    useEffect(() => {
        const timer = setInterval(() => {
            if (seconds > 0) setSeconds(seconds - 1);
            else {
                if (minutes === 0 && seconds === 0) {
                    clearInterval(timer);
                }
                else {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                }
            }
        }, 1000);

        return () => clearInterval(timer);
    }, [minutes, seconds]);

    const handleChange = (element, index) => {
        if (isNaN(element.value)) return;

        const newCode = [...code];
        newCode[index] = element.value;
        setCode(newCode);

        if (index < inputRefs.current.length - 1 && element.value) {
            inputRefs.current[index + 1].current.focus();
        }
    };

    const handleSubmitOtp = (event) => {
        event.preventDefault();
        const otp = code.join("");
        const v1 = Keys.OTP_REGEX.test(otp);

        if (!v1 || !auth.user) {
            props.setToast({ content: t('INVALID_ENTRY'), variant: 'danger', title: t('ERROR'), show: true });
            return;
        }

        const data = { email: auth.user.email, otp };
        AxiosHelper.verifyOtp(data).then((res) => {
            if (res.status == 200) {
                props.setToast({ content: t('DONE'), variant: 'success', title: t('SUCCESS'), show: true });
                props.setStep(3);
            }
        }).catch((err) => {
            if (!err?.response) {
                props.setToast({ content: t('NO_SERVER_RESPONSE'), variant: 'danger', title: t('ERROR'), show: true });
                return;
            }
            switch(err.response.status) {
                case 400: props.setToast({ content: t('EMAIL_OR_OTP_NOT_ENTERED'), variant: 'danger', title: t('INVALID_ENTRY'), show: true }); break;
                case 402: props.setToast({ content: t('EXPIRED_CODE'), variant: 'danger', title: t('UNSUCCESS'), show: true }); break;
                case 404: props.setToast({ content: t('VERIF_CODE_NOT_FOUND'), variant: 'warning', title: t('NOT_FOUND'), show: true }); break;
                case 405: props.setToast({ content: t('INCORRECT_CODE_ENTERED'), variant: 'danger', title: t('NOT_ALLOWED'), show: true }); break;
                default : props.setToast({ content: err.response.data.msg, variant: 'danger', title: t('ERROR'), show: true }); break;
            }
        });
    }

    const resendCode = () => {
        setMinutes(4);
        const data = { email: auth.user.email };
        AxiosHelper.sendEmailVerification(data).then((res) => {
            if (res.status == 200) {
                props.setToast({ content: t('VERIFY_YOUR_EMAIL'), variant: 'primary', title: t('SUCCESS'), show: true });
            }
        })
    }

    return (
        <>
            <img src={icEmailVerification} />
            <span className="text-center fs-4 medium fg-dark mt-2">{t('EMAIL_VERIFICATION')}</span>
            <span className="text-center font-14 fg-green-500 mt-1">{auth.user ? auth.user.email : 'example@gmail.com'}</span>
            <span className="text-center font-14 fg-dark">{t('ENTER_VERIF_CODE_THAT_SENT_EMAIL')}</span>
            <div className="container col-md-3 mt-4">
                <form className="d-flex flex-column" onSubmit={handleSubmitOtp} noValidate>
                    {minutes > 0 && <div className="d-flex flex-row">
                        {code.map((digit, index) => {
                            return (
                                <InputOtpCode key={index} index={index}
                                    inputRefs={inputRefs}
                                    inputClass={'col-md-10 text-center'}
                                    id={`otpCode${index + 1}`}
                                    value={digit}
                                    autoFocus={index === 0}
                                    handleChange={handleChange}
                                />
                            )
                        })}
                    </div>}
                    {minutes > 0 ? <div className="d-flex flex-column align-items-center mt-2 font-14">
                        <span className="fg-dark">{t('REMAINING_TIME')}</span>
                        <span className="fg-red-300">{minutes-1}:{seconds < 10 ? `0${seconds}` : seconds}</span>
                    </div>
                    : <span onClick={resendCode} className="text-center text-primary font-14 mt-3 hover-cursor">{t('RESEND_CODE')}</span>
                    }
                    <button type="submit" className="button-u bg-sp-primary col-12 mt-3" disabled={(!code)}>
                        {t('CONFIRM')}
                    </button>
                    <button onClick={() => navigate(-1)} className="button-u fg-dark col-12 mt-2">
                        {t('BACK')}
                    </button>
                </form>
            </div>
        </>
    );
}

export default VerifyOtp;