import { useTranslation } from 'react-i18next';

const AppointmentStates = () => {
    const { t } = useTranslation();

    const APPOINTMENT_STATES = new Map([
        ['REVIEWING', t('REVIEWING')], ['ACCEPTED', t('ACCEPTED')],
        ['IN_PROCESS', t('IN_PROCESS')], ['INVITATION', t('INVITATION')],
        ['COMPLETED', t('COMPLETED')], ['REJECTED', t('REJECTED')]
    ]);

    return APPOINTMENT_STATES;
};

export default AppointmentStates;