import { useEffect, useState } from 'react';
import useAuth from '../../../../../hooks/useAuth';
import useAxiosPrivate from './../../../../../hooks/useAxiosPrivate'
import MainWrapper from './../../layouts/MainWrapper';
import Keys from '../../../../../utils/constants';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { useTranslation } from "react-i18next";
import CountItem from './components/CountItem';
import UserAvatar from "./../../../../../pages/Admin/components/ui/UserAvatar";
import Utils from "../../../../../utils";
import TableStd from '../../../../Admin/components/ui/TableStd';
import SortU from "../../../../Admin/components/ui/SortU";
import Config from "../../../../../config";
import State from "../../components/State";
import { Link } from 'react-router-dom';

const Home = ({...props}) => {
    const { ready } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const [usersCount, setUsersCount] = useState();
    const [appointmentsCount, setAppointmentsCount] = useState();
    const [appointmentsInProcessCount, setAppointmentsInProcessCount] = useState();
    const [appointmentsDoneCount, setAppointmentsDoneCount] = useState();

    const [appointments, setAppointments] = useState([]);

    const [query, setQuery] = useState({
        page: 1,
        limit: 10,
        order: [['createdAt', 'DESC']]
    });
    
    useEffect(() => {
        if (ready) {
            getUsersCount();
            getAppointmentsCount(null, setAppointmentsCount)
            getAppointmentsCount(Keys.APPOINTMENT_STATE.get('2'), setAppointmentsInProcessCount)
            getAppointmentsCount(Keys.APPOINTMENT_STATE.get('4'), setAppointmentsDoneCount)
        
            getAppointments();
        }
    }, [ready]);

    const getUsersCount = () => {
        axiosPrivate.get('/count/users').then((res) => {
            if (res.status == 200) setUsersCount(res.data);
        })
    }

    const getAppointmentsCount = (state, setCount) => {
        const query = { filter: {state} }
        axiosPrivate.get('/count/appointments', { params: query }).then((res) => {
            if (res.status == 200) setCount(res.data);
        })
    }

    const getAppointments = () => {
        setLoading(true);
        axiosPrivate.get('/appointments', { params: query }).then((res) => {
            if (res.status == 200) {
                setAppointments(res.data.data);
            }
        }).finally(() => {setLoading(false)});
    }
   
    const breadcrumbs = [ { title: t('MAIN'), active: true } ];

    const modelProps = [
        { 
            name: <SortU title={t('CUSTOMER')} attr={'email'} query={query} setQuery={setQuery}/>,
            value: (prop) => {
                return(
                    <div className="d-flex flex-row align-items-center">
                        <UserAvatar user={prop} style={'bg-blue-100 bg-gradient fg-blue-500 wh-30'}/>
                        <div className="d-flex flex-column ms-2">
                            <span className="font-12">{prop.fullName}</span>
                            <span className="small-x medium">{prop.email}</span>
                        </div>
                    </div>
                )
            } 
        },
        {
            name: <SortU title={t('NUMBER')} attr={'number'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{prop.number}</>) } 
        },
        {
            name: t('SERVICE'),
            value: (prop) => {
                return(
                    <div className="d-flex flex-column">
                        <span className="medium">{prop.service.country.name}</span>
                        <span className="small-x">{prop.service.visaType.name}</span>
                    </div>
                )
            } 
        },
        {
            name: <SortU title={t('PHONE_NUMBER')} attr={'phoneNumber'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>+{prop.phoneNumber}</>) } 
        },
        {
            name: <SortU title={t('UPDATED_AT')} attr={'updatedAt'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<>{Utils.getTimestampDateHm(prop.updatedAt)}</>) }
        },
        {
            name: <SortU title={t('STATUS')} attr={'state'} query={query} setQuery={setQuery}/>,
            value: (prop) => { return(<div className="mt-2"><State state={prop.state}/></div>) }
        },

        {
            name: "",
            value: (prop) => {
                return (
                    <Link to={`${Config.ADMIN_PREFIX}/appointments/${prop.id}`}>
                        <FontAwesomeIcon icon={faEye} className="fg-dark font-14 bg-neutral rounded-circle p-2"/>
                    </Link>
                )
            }
        }
];

    return (
        <MainWrapper rootPage={true} loading={props.loading} breadcrumbs={breadcrumbs}>
            <div className='d-flex flex-column bg-white rounded-3 mx-3 my-3 px-3 pt-2 pb-4'>
                <div className='d-flex flex-row align-items-center justify-content-between'>
                    <span className='fg-dark font-22 medium ms-2'>{t('STATISTICS')}</span>
                    <span></span>
                </div>
                <div className='d-flex flex-row align-items-center justify-content-between mt-3 mx-4'>
                    <CountItem title={t('APPOINTMENTS')} count={appointmentsCount}/>
                    <CountItem title={t('CONTINUE')} count={appointmentsInProcessCount}/>
                    <CountItem title={t('DONE')} count={appointmentsDoneCount}/>
                    <CountItem title={t('USERS')} count={usersCount}/>
                </div>
            </div>
            <div className='d-flex flex-column mx-3'>
                <div className='d-flex flex-row align-items-center justify-content-between rounded-4 bg-white px-3 py-3 mb-3'>
                    <span className='fg-dark'>{t('LATEST_APPOINTMENTS')}</span>
                    <Link to={`${Config.ADMIN_PREFIX}/appointments`} className='no-decor font-14'>{t('SHOW_ALL')}</Link>
                </div>
                <TableStd modelProps={modelProps} data={appointments} count={10} bgHead={'bg-light'}/>
            </div>
        </MainWrapper>
    );
};

export default Home;