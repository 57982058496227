import { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import useAuth from "../../../../../hooks/useAuth";
import useAxiosPrivate from '../../../../../hooks/useAxiosPrivate';
import { faSearch, faArrowRotateRight } from "@fortawesome/free-solid-svg-icons";
import MainWrapper from './../../layouts/MainWrapper';
import InputField from "../../../../Admin/components/form/InputField";
import DataNotFound from '../../../../Admin/components/ui/DataNotFound';
import ButtonU from "../../../../Admin/components/ui/ButtonU";
import { useTranslation } from "react-i18next";
import Config from "../../../../../config";
import ServiceCard from "./components/ServiceCard";

const Services = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const { ready } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const [loading, setLoading] = useState(false);

    const [services, setServices] = useState([]);
    const [servicesCount, setServicesCount] = useState();

    const parsed = queryString.parse(location.search);
    const urlQuery = {
        order: parsed.order || [['createdAt', 'DESC']],
        filter: {}
    }
    const [query, setQuery] = useState(urlQuery);

    const [find, setfind] = useState('');

    const breadcrumbs = [
        { title: t('MAIN'), link: `${Config.ADMIN_PREFIX}/`}, { title: t('SERVICES'), active: true }
    ]

    useEffect(() => {
        if (ready) {
            getUpdatedUrl();
            getServices();
        }
    }, [ready, query]);

    const getUpdatedUrl = () => {
        parsed.page = query.page;
        window.history.replaceState({}, '', `${location.pathname}?${queryString.stringify(parsed)}`);
    }

    const getServices = () => {
        setLoading(true);
        axiosPrivate.get('/services', { params: query }).then((res) => {
            if (res.status == 200) {
                setServices(res.data.data);
                setServicesCount(res.data.count);
            }
        }).finally(() => {setLoading(false)});
    }

    useEffect(() => {
        setQuery({...query, filter: { name: find }});
    }, [find]);
 
    const refresh = () => { getServices() }

    return (
        <MainWrapper loading={loading} breadcrumbs={breadcrumbs}>
            <div className="head-long-page">
                <span className="bold fs-5 fg-dark">{t('SERVICES')}</span>
                <div className="d-flex align-items-center">
                    <ButtonU onSubmit={refresh} className={'border-dark fg-dark'} icon={faArrowRotateRight}/>
                    <InputField type={"text"} value={find} setValue={setfind} hint={`${t('COUNTRY')}`} icon={faSearch} formClass="input-field-container me-1 font-14" inputClass="input-field-outline"/> 
                </div>
            </div>
            { servicesCount ?
                <div className="row mx-0 mt-5">
                    {services.map((service) => {
                        return (
                            <ServiceCard service={service} />
                        )
                    })}
                </div>
                : <DataNotFound/>
            }
            <div className="mb-5"></div>
        </MainWrapper>
    );
}

export default Services;