import DataWrapper from "../DataWrapper";
import Option from "./../../../components/Option";
import icChatRound from "../../../../../../assets/svg/ic_chat_round.svg";
import icArchiveBook from "../../../../../../assets/svg/ic_archive_book.svg";
import icLayer from "../../../../../../assets/svg/ic_layer.svg";
import { useTranslation } from "react-i18next";
import Config from "../../../../../../config";

const General = () => {
    const { t } = useTranslation();

    const breadcrumbs = [
        { title: t('SETTINGS'), link: `${Config.ADMIN_PREFIX}/settings/profile`}, { title: t('DATABASE'), link: `${Config.ADMIN_PREFIX}/settings/data/general`}, { title: t('GENERAL'), active: true }
    ]

    const options = [
        { id: 'contacts', name: t('CONTACTS'), icon: icChatRound, link: `${Config.ADMIN_PREFIX}/settings/data/general/contacts`},
        { id: 'policy-documents', name: t('POLICY_DOCUMENTS'), icon: icArchiveBook, link: `${Config.ADMIN_PREFIX}/settings/data/general/policy-documents`},
        { id: 'services', name: t('SERVICES'), icon: icLayer, link: `${Config.ADMIN_PREFIX}/settings/data/general/services`},
    ]

    return (
        <DataWrapper breadcrumbs={breadcrumbs}>
            <div className="row p-3">
                { options.map((option) => {
                    return (<Option key={option.id} item={option}/>)
                })}
            </div>
        </DataWrapper>
    );
}

export default General;