import { Link } from "react-router-dom";
import icLogo from './../../../assets/svg/ic_logo.svg';
import Config from "../../../config";
import DropdownLang from "./../../../layouts/DropdownLang";

const Wrapper = (props) => {
    return (
        <div>
            <div className="col-12 login-header">
                <Link to={`${Config.ADMIN_PREFIX}/auth/signin`} className="no-decor">
                    <img src={icLogo} />
                </Link>
                <DropdownLang/>
            </div>
            <div className="min-vh-100 d-flex flex-column align-items-center justify-content-center">
                { props.children }
            </div>
        </div>
    );
}

export default Wrapper;