import { useState, useEffect } from "react";
import useAuth from "../../../../../../../hooks/useAuth";
import useAxiosPrivate from '../../../../../../../hooks/useAxiosPrivate';
import { faAdd } from "@fortawesome/free-solid-svg-icons";
import DataWrapper from "../../DataWrapper";
import ButtonU from "../../../../../../Admin/components/ui/ButtonU";
import ToastU from "../../../../../../Admin/components/ui/ToastU";
import Create from "./Create";
import Update from "./Update";
import Delete from "./Delete";
import { useTranslation } from "react-i18next";
import Config from "../../../../../../../config";
import PolicyDocumentTypes from './components/PolicyDocumentTypes';
import PolicyDocumentCard from "./components/PolicyDocumentCard";

const PolicyDocuments = () => {
    const { t } = useTranslation();
    const { ready } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const [loading, setLoading] = useState(false);
    const policyDocumentTypes = PolicyDocumentTypes()

    const [policyDocuments, setPolicyDocuments] = useState([]);

    const [query, setQuery] = useState({
        order: [['createdAt', 'ASC']],
    });

    const [modalCreate, setModalCreate] = useState(false);
    const [modalUpdate, setModalUpdate] = useState(false);
    const [modalDelete, setModalDelete] = useState(false);
    
    const [itemToBe, setItemToBe] = useState({});

    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    const breadcrumbs = [
        { title: t('SETTINGS'), link: `${Config.ADMIN_PREFIX}/settings/profile`}, { title: t('DATABASE'), link: `${Config.ADMIN_PREFIX}/settings/data/general`}, { title: t('GENERAL'), link: `${Config.ADMIN_PREFIX}/settings/data/general` }, { title: t('POLICY_DOCUMENTS'), active: true }
    ]

    useEffect(() => {
        if (ready) {
            getPolicyDocuments();
        }
    }, [ready, query, toast]);

    const getPolicyDocuments = () => {
        setLoading(true);
        axiosPrivate.get('/policy-documents', { params: query }).then((res) => {
            if (res.status == 200) {
                setPolicyDocuments(res.data.data);
            }
        }).finally(() => {setLoading(false)});
    }

    const createNew = () => { setModalCreate(true); }

    return (
        <DataWrapper loading={loading} breadcrumbs={breadcrumbs}>
            <div className="head-long-page">
                <span className="bold fs-5 fg-dark">{t('POLICY_DOCUMENTS')}</span>
                <div className="d-flex align-items-center">
                    <ButtonU onSubmit={createNew} className={'border-success fg-green-500'} icon={faAdd} text={t('ADD')}/>
                </div>
            </div>
            { policyDocuments.length > 0 &&
                <div className="row mx-0 mt-5">
                    {policyDocuments.map((policyDocument) => {
                        return (
                            <PolicyDocumentCard
                                policyDocument={policyDocument}
                                policyDocumentTypes={policyDocumentTypes}
                                setModalUpdate={setModalUpdate}
                                setModalDelete={setModalDelete}
                                itemToBe={itemToBe}
                                setItemToBe={setItemToBe}
                            />
                        )
                    })}
                </div>
            }
            <Create
                modalShow={modalCreate}
                setModalShow={setModalCreate}
                toast={toast}
                setToast={setToast}
                setLoading={setLoading}
            />
            <Update
                modalShow={modalUpdate}
                setModalShow={setModalUpdate}
                policyDocumentTypes={policyDocumentTypes}
                toast={toast}
                setToast={setToast}
                item={itemToBe}
                setLoading={setLoading}
            />
            <Delete
                modalShow={modalDelete}
                setModalShow={setModalDelete}
                toast={toast}
                setToast={setToast}
                item={itemToBe}
                setLoading={setLoading}
            />
            <ToastU toast={toast} setToast={setToast}/>
            <div className="mb-5"></div>
        </DataWrapper>
    );
}

export default PolicyDocuments;