import { useEffect, useState } from "react";
import useAxiosPrivate from './../../../../../../../hooks/useAxiosPrivate';
import ModalU from './../../../../../../Admin/components/ui/ModalU';
import Utils from "./../../../../../../../utils";
import SelectLabeled from '../../../../../components/form/SelectLabeled';
import SwitchLabeled from '../../../../../components/form/SwitchLabeled';
import { useTranslation } from "react-i18next";

const Update = ({...props}) => {
    const { t } = useTranslation();
    const axiosPrivate = useAxiosPrivate();
    const item = props.item;
    
    const [countryId, setCountryId] = useState('');
    const [visaTypeId, setVisaTypeId] = useState('');
    const [active, setActive] = useState(true);

    useEffect(() => {
        setCountryId(item.countryId);
        setVisaTypeId(item.visaTypeId);
        setActive(item.active);
    }, [item])

    const handleSubmit = (event) => {
        event.preventDefault();
        
        const requiredInputs = [
            { key: countryId, value: t('COUNTRY_REQUIRED') },
            { key: visaTypeId, value: t('VISA_TYPE_REQUIRED') },
        ]

        const content = Utils.errorHandling(requiredInputs);
        if (content) {
            props.setToast({ content, variant: 'danger', title: t('ERROR'), show: true });
            return;
        }

        props.setLoading(true);
        const data = { countryId, visaTypeId, active };
        axiosPrivate.put('/services/update/'+item.id, data).then((res) => {
            if (res.status == 200) {
                props.setModalShow(false);
                props.setToast({ content: t('SUCCESS'), variant: 'success', title: t('SUCCESS'), show: true });
            }
        }).catch((err) => {
            if (!err?.response) props.setToast({ content: t('NO_SERVER_RESPONSE'), variant: 'danger', title: t('ERROR'), show: true });
            else props.setToast({ content: err.response.data.msg, variant: 'danger', title: t('ERROR'), show: true });
        }).finally(() => {props.setLoading(false)});
    }

    return (
        <ModalU show={props.modalShow} size={''} title={`${t('SERVICE')} [${t('EDIT')}]`}
            actionbtntext={t('UPDATE')}
            actionbtnclassname={"bg-green-700 font-14 medium text-white"}
            onSubmit={handleSubmit} onHide={props.setModalShow}>
                
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <SelectLabeled label={t('COUNTRY')} items={props.countries} value={countryId} setValue={setCountryId} required={true} className={'col-md-6'}/>
                    <SelectLabeled label={t('VISA_TYPE')} items={props.visaTypes} value={visaTypeId} setValue={setVisaTypeId} required={true} className={'col-md-6'}/>
                    <SwitchLabeled label={t('ACTIVE')} value={active} setValue={setActive} size="small" required={true} className={'col-md-6 mt-2'}/>
                </div>
            </form>
        </ModalU>
    );
}

export default Update