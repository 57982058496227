import icHrDot from './../../../../../../../../assets/svg/ic_hr_dot.svg';
import icPen from './../../../../../../../../assets/svg/ic_pen.svg';
import icTrash from './../../../../../../../../assets/svg/ic_trash.svg';
import DropdownU2 from '../../../../../../../Admin/components/ui/DropdownU2';
import { useTranslation } from "react-i18next";
import Utils from "../../../../../../../../utils";

const PolicyDocumentCard = ({policyDocument, policyDocumentTypes, setModalUpdate, setModalDelete, setItemToBe}) => {
    const { t } = useTranslation();
   
    const options = [
        {
            name: t('EDIT'),
            icon: icPen,
            color: "fg-dark",
            onClick: () => {
                setItemToBe(policyDocument);
                setModalUpdate(true);
            }
        },
        // {
        //     name: t('DELETE'),
        //     icon: icTrash,
        //     color: "fg-dark",
        //     onClick: () => { 
        //         setItemToBe(policyDocument);
        //         setModalDelete(true);
        //     }
        // }
    ]

    return (
        <div className="col-md-3">
            <div className="d-flex flex-column align-items-center bg-white my-3 p-3 rounded-4">
                <div className="d-flex flex-row justify-content-between col-md-12">
                    <span></span>
                    <DropdownU2 className='d-flex align-items-center justify-content-center' toggleClass={"p-1"} icon={icHrDot} items={options}/>
                </div>
                <span className="fg-dark font-18 medium mt-1">{policyDocumentTypes.get(policyDocument.type)}</span>
                <span className="fg-gray font-13 mt-1">v{policyDocument.version}</span>
                <hr className="my-3 fg-gray col-md-12"/>
                <span className="fg-dark font-13">{Utils.getTimestampDate(policyDocument.updatedAt)}</span>
            </div>
        </div>
    );
}

export default PolicyDocumentCard;