import convertSize from "convert-size";
import icPdf from './../../../../../../../assets/svg/ic_pdf.svg';

const RequiredDocument = ({item, onChange}) => {
    return (
        <div className="col-md-6">
            <div className="d-flex flex-row justify-content-between bg-light border border-gray rounded-4 my-1 py-2 px-3">
                <div className="d-flex flex-column">
                    <div className="d-flex flex-row align-items-center">
                        <span className="fg-dark font-13">{item.documentType.name}</span>
                        {item.file && <span className="fg-green-500 small medium ms-2">{convertSize(item.file.size, {accuracy: 3})}</span>}
                    </div>
                    <span className="fg-gray small">{item.additionalText ? item.additionalText : '-'}</span>
                </div>
                <div className="d-flex flex-row align-items-center bg-white border border-gray rounded-2 py-1 px-1">
                    <img src={icPdf} alt="excel" className="wh-20 me-1"/>
                    <input id="uploadBtn" type="file" accept="application/pdf" className="small-x" onChange={onChange}/>
                </div>
            </div>
        </div>
    )
}

export default RequiredDocument;