export default class Config {
    static CO_NAME = "Anonymous"
    static APP_NAME = "Tkm Visa";
    
    static IS_DEV = false;
    static URL_SERVER = this.IS_DEV ? 'http://127.0.0.1:4000' : 'https://safetkmvisa.com';
    static ADMIN_PREFIX = '/tkmvisa-admin'
    static PATH_PREFIX = this.IS_DEV ? '/static' : '/api/static';
    static PATH_BANKS = '/images/banks/';
    static PATH_BANNERS = '/images/banners/';
    static PATH_COUNTRY_BANNERS = '/images/countries/banners/';
    static PATH_COUNTRY_FLAGS = '/images/countries/flags/';
    static PATH_APPOINTMENT_DOCS = '/docs/appointment-docs/';
    static PATH_PHOTOS = '/docs/photos/';
    static PATH_INVITATIONS = '/docs/invitations/';
    static PATH_RECEIPTS = '/docs/receipts/'
}