import { useTranslation } from 'react-i18next';
import icEditLine from './../../../../../../../assets/svg/ic_edit_line.svg';

const SelectState = ({...props}) => {
    const { t } = useTranslation();

    const handleChange = (event) => {
        props.setState(event.target.value);
    }

    return (
        <div className="d-flex flex-row align-items-center rounded-4 bg-lime-200 py-1 px-2">
            <img src={icEditLine} className="ms-1 wh-20" />
            <select
                value={props.state}
                onChange={handleChange}
                className={`font-13 rounded-4 border border-light bg-lime-200 px-2 py-1`}>
                <option value={""} disabled hidden>{t('CHOOSE')}</option>
                { props.items.map((item) => {
                    return (<option value={item.state} key={item.state}>{item.value}</option>)
                }) }
            </select>
        </div>
    );
}

export default SelectState;