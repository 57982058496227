import React, { useEffect, useState, useRef } from "react";
import useAuth from "./../../../hooks/useAuth";
import AxiosHelper from "./../../../api/AxiosHelper";
import { useNavigate } from 'react-router-dom';
import Config from "../../../config";
import Keys from "./../../../utils/constants";
import icChangePassword from './../../../assets/svg/ic_change_password.svg';
import icLock from './../../../assets/svg/ic_lock.svg';
import icEyeOpen from './../../../assets/svg/ic_eye_open.svg';
import InputLabelIcon from "../components/form/InputLabelIcon";
import { useTranslation} from 'react-i18next';

const SetNewPassword = ({...props}) => {
    const navigate = useNavigate();
    const { auth } = useAuth();
    const { t } = useTranslation();
    const passwordRef = useRef();
    
    const [password, setPassword] = useState('');
    const [validPassword, setValidPassword] = useState(false);

    const [matchPassword, setMatchPassword] = useState('');
    const [validMatchPassword, setValidMatchPassword] = useState(false);

    useEffect(() => {
        setValidPassword(Keys.PASSWORD_REGEX.test(password));
        const match = (password === matchPassword);
        setValidMatchPassword(match);
    }, [password, matchPassword]);

    const handleSubmitResetPassword = (event) => {
        event.preventDefault();
        const v1 = Keys.PASSWORD_REGEX.test(password);

        if (!v1 || !auth.user) {
            props.setToast({ content: t('INVALID_ENTRY'), variant: 'danger', title: t('ERROR'), show: true });
            return;
        }
        
        const data = { email: auth.user.email, password }; 
        AxiosHelper.resetPassword(data).then((res) => {
            if (res.status == 200) {
                props.setToast({ content: t('DONE'), variant: 'success', title: t('SUCCESS'), show: true });
                navigate(`${Config.ADMIN_PREFIX}/auth/signin`);
            }
        }).catch((err) => {
            if (!err?.response) {
                props.setToast({ content: t('NO_SERVER_RESPONSE'), variant: 'danger', title: t('ERROR'), show: true });
                return;
            }
            switch(err.response.status) {
                case 401: props.setToast({ content: t('ACCOUNT_NOT_VERIFIED'), variant: 'danger', title: t('UNAUTHOURIZED'), show: true }); break;
                case 403: props.setToast({ content: t('EXPIRED_TIME'), variant: 'danger', title: t('UNAUTHOURIZED'), show: true }); break;
                default : props.setToast({ content: err.response.data.msg, variant: 'danger', title: t('ERROR'), show: true }); break;
            }
        });
    }

    return (
        <>
            <img src={icChangePassword} />
            <span className="text-center fs-4 medium fg-dark mt-2">{t('NEW_PASSWORD')}</span>
            <span className="text-center font-14 fg-green-500 mt-1">{auth.user ? auth.user.email : 'example@gmail.com'}</span>
            <span className="text-center font-14 fg-dark">{t('ENTER_VERIF_CODE_THAT_SENT_EMAIL')}</span>
            <div className="container col-md-3 mt-4">
                <form onSubmit={handleSubmitResetPassword} noValidate>
                    <InputLabelIcon 
                        formClass={'mt-3 mb-2'} inputClass={'col-md-12'}
                        id={'password'} type={'password'} ref={passwordRef}
                        value={password} setValue={setPassword}
                        hint={t('PASSWORD')} autoFocus={true} required={true}
                        label={t('PASSWORD')} labelClass={'fg-dark'} icon={icLock} iconRight={icEyeOpen}
                    />
                    <InputLabelIcon 
                        formClass={'mt-3 mb-2'} inputClass={'col-md-12'}
                        id={'confirm_password'} type={'password'}
                        value={matchPassword} setValue={setMatchPassword}
                        hint={t('CONFIRM_PASSWORD')} autoFocus={false} required={true}
                        label={t('CONFIRM_PASSWORD')} labelClass={'fg-dark'} icon={icLock} iconRight={icEyeOpen}
                    />
                    <button type="submit" className="button-u bg-sp-primary col-12 my-3" disabled={(!validPassword || !validMatchPassword)}>
                        {t('CONFIRM')}
                    </button>
                    <button onClick={() => navigate(-1)} className="button-u fg-dark col-12 my-3">
                        {t('BACK')}
                    </button>
                </form>
            </div>
        </>
    );
}

export default SetNewPassword;