import UserAvatar from "./../../../../../../pages/Admin/components/ui/UserAvatar";
import icHrDot from './../../../../../../assets/svg/ic_hr_dot.svg';
import icMail from './../../../../../../assets/svg/ic_mail.svg';
import icPhone from './../../../../../../assets/svg/ic_phone.svg';
import icPen from './../../../../../../assets/svg/ic_pen.svg';
import icTrash from './../../../../../../assets/svg/ic_trash.svg';
import DropdownU2 from '../../../../../Admin/components/ui/DropdownU2';
import { useTranslation } from "react-i18next";

const EmployeeCard = ({employee, setModalUpdate, setModalDelete, setItemToBe}) => {
    const { t } = useTranslation();
   
    const options = [
        {
            name: t('EDIT'),
            icon: icPen,
            color: "fg-dark",
            onClick: () => {
                setItemToBe(employee);
                setModalUpdate(true);
            }
        },
        {
            name: t('DELETE'),
            icon: icTrash,
            color: "fg-dark",
            onClick: () => { 
                setItemToBe(employee);
                setModalDelete(true);
            }
        }
    ]

    return (
        <div className="col-md-3">
            <div className="d-flex flex-column align-items-center bg-white my-3 p-3 rounded-4">
                <div className="d-flex flex-row justify-content-between col-md-12">
                    <span className='wh-30'></span>
                    <UserAvatar user={employee} style={'bg-orange-200 font-18 wh-50'}/>
                    <DropdownU2 className='d-flex align-items-center justify-content-center' toggleClass={"p-1"} icon={icHrDot} items={options}/>
                </div>
                <span className="fg-dark font-18 medium mt-3">{employee.fullName}</span>
                <span className="fg-gray font-13 mt-1">{employee.position}</span>
                <hr className="my-3 fg-gray col-md-12"/>
                <div className="d-flex flex-row align-items-center">
                    <img src={icMail} className="me-2"/>
                    <span className="fg-dark font-13">{employee.email}</span>
                </div>
                <div className="d-flex flex-row align-items-center mt-3">
                    <img src={icPhone} className="me-2"/>
                    <span className="fg-dark font-13">{employee.phoneNumber}</span>
                </div>
            </div>
        </div>
    );
}

export default EmployeeCard;