import { useEffect, useState } from "react";
import useAxiosPrivate from './../../../../../../hooks/useAxiosPrivate';
import { useTranslation } from "react-i18next";
import Config from "../../../../../../config";
import { Switch } from "@mui/material";

const ServiceCard = ({service}) => {
    const { t } = useTranslation();
    const axiosPrivate = useAxiosPrivate();
   
    const [active, setActive] = useState(true);

    useEffect(() => {
        setActive(service.active);
    }, [service]);

    const handleChange = (e) => {
        setActive(e.target.checked);
        const data = { active: e.target.checked };
        axiosPrivate.put('/services/update/'+service.id, data);
    }

    return (
        <div className="col-md-3">
            <div className="d-flex flex-column bg-white my-2 p-3 border border-gray rounded-4">
                <div className="country-flag-countainer">
                    <img src={`${Config.URL_SERVER}${Config.PATH_PREFIX}${Config.PATH_COUNTRY_FLAGS}${service.country.flag}`} className="country-flag-circle"/>
                </div>
                <div className="d-flex flex-row justify-content-between align-items-end">
                    <div className="d-flex flex-column mt-2">
                        <span className="fg-dark font-13">{service?.country?.name}</span>
                        <span className="fg-gray small">{service?.visaType?.name}</span>
                    </div>
                    <Switch checked={active} onChange={handleChange} size={'small'}/>
                </div>
            </div>
        </div>
    );
}

export default ServiceCard;