import { Link, useLocation, useNavigate } from "react-router-dom"
import Utils from "./../../utils";
import icLogo from "./../../assets/svg/ic_logo.svg";
import Config from "../../config";

const NavBar = ({...props}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const items = props.items;

    const isActive = (links) => {
        let flag = links.some((link) => {
            const baseLink = link.split('/')[2];
            const path = location.pathname.split('/')[2];
            return (baseLink === path);
        });
        
        return flag;
    }

    return (
        <div className="nav-bar" id="nav-bar">
            <img src={icLogo} alt="logo" onClick={() => navigate(`${Config.ADMIN_PREFIX}`)} className="wh-80 bg-white rounded-2 hover-cursor"/>
            <hr className="fg-gray"/>
            { items.map((item, index)=>{
                return (
                    <Link to={item.links[0]} key={item.id}
                        className={"nav-link " + (!isActive(item.links) && 'collapsed')} data-bs-target={`#${item.id}`} data-bs-toggle="collapse">
                        <img src={item.icon} className="nav-icon" />
                        <span >{Utils.truncateText(item.name)}</span>
                    </Link>
                );
            })}
        </div>
    );
}

export default NavBar;