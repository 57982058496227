import Wrapper from "./../../../../../layouts/Admin/Wrapper";
import SideBarData from "../../layouts/SideBarData";
import { useTranslation } from "react-i18next";
import Keys from "./../../../../../utils/constants";
import icObjectsColumn from "./../../../../../assets/svg/ic_objects_column.svg";
import icReciept from "./../../../../../assets/svg/ic_reciept.svg";
import icSquareDollarChart from "./../../../../../assets/svg/ic_square_dollar_chart.svg";

const DataWrapper = (props) => {
    const { t } = useTranslation();

    const items = [
        { name: t('GENERAL'), id: "general", icon: icObjectsColumn, links: Keys.SETTINGS_DATA_GENERAL_LINKS},
        // { name: t('SERVICES'), id: "services", icon: icReciept, links: Keys.SETTINGS_DATA_SERVICES_LINKS},
        { name: t('FINANCE'), id: "finance", icon: icSquareDollarChart, links: Keys.SETTINGS_DATA_FINANCE_LINKS},
    ];

    return (
        <Wrapper loading={props.loading} breadcrumbs={props.breadcrumbs}>
            <div className="main-page-settings">
                <SideBarData items={items}/>
                <div className="body-page-settings">
                    { props.children }
                </div>
            </div>
        </Wrapper>
    );
};

export default DataWrapper;