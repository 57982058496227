import { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import useAuth from "../../../../../hooks/useAuth";
import useAxiosPrivate from '../../../../../hooks/useAxiosPrivate';
import { faAdd, faSearch, faArrowRotateRight } from "@fortawesome/free-solid-svg-icons";
import MainWrapper from './../../layouts/MainWrapper';
import InputField from "../../../../Admin/components/form/InputField";
import DataNotFound from '../../../../Admin/components/ui/DataNotFound';
import ButtonU from "../../../../Admin/components/ui/ButtonU";
import ToastU from "../../../../Admin/components/ui/ToastU";
import { useTranslation } from "react-i18next";
import Config from "../../../../../config";
import Create from "./Create";
import Update from "./Update";
import Delete from "./Delete";
import BannerCard from "./components/BannerCard";

const Banners = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const { ready } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const [loading, setLoading] = useState(false);

    const [banners, setBanners] = useState([]);
    const [bannersCount, setBannersCount] = useState();

    const parsed = queryString.parse(location.search);
    const urlQuery = {
        order: parsed.order || [['createdAt', 'DESC']],
        filter: {}
    }
    const [query, setQuery] = useState(urlQuery);

    const [find, setfind] = useState('');

    const [modalCreate, setModalCreate] = useState(false);
    const [modalUpdate, setModalUpdate] = useState(false);
    const [modalDelete, setModalDelete] = useState(false);
    
    const [itemToBe, setItemToBe] = useState({});
    
    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    const breadcrumbs = [
        { title: t('MAIN'), link: `${Config.ADMIN_PREFIX}/`}, { title: t('BANNERS'), active: true }
    ]

    useEffect(() => {
        if (ready) {
            getUpdatedUrl();
            getBanners();
        }
    }, [ready, query, toast]);

    const getUpdatedUrl = () => {
        parsed.page = query.page;
        window.history.replaceState({}, '', `${location.pathname}?${queryString.stringify(parsed)}`);
    }

    const getBanners = () => {
        setLoading(true);
        axiosPrivate.get('/banners', { params: query }).then((res) => {
            if (res.status == 200) {
                setBanners(res.data.data);
                setBannersCount(res.data.count);
            }
        }).finally(() => {setLoading(false)});
    }

    useEffect(() => {
        setQuery({...query, filter: { title: find }});
    }, [find]);
 
    const createNew = () => { setModalCreate(true) }

    const refresh = () => { getBanners() }

    return (
        <MainWrapper loading={loading} breadcrumbs={breadcrumbs}>
            <div className="head-long-page">
                <span className="bold fs-5 fg-dark">{t('BANNERS')}</span>
                <div className="d-flex align-items-center">
                    <ButtonU onSubmit={refresh} className={'border-dark fg-dark'} icon={faArrowRotateRight}/>
                    <InputField type={"text"} value={find} setValue={setfind} hint={t('TITLE')} icon={faSearch} formClass="input-field-container me-1 font-14" inputClass="input-field-outline"/> 
                    <ButtonU onSubmit={createNew} className={'border-success fg-green-500'} icon={faAdd} text={t('ADD')}/>
                </div>
            </div>
            { bannersCount ?
                <div className="row mx-0 mt-5">
                    {banners.map((banner) => {
                        return (
                            <BannerCard
                                banner={banner}
                                setModalUpdate={setModalUpdate}
                                setModalDelete={setModalDelete}
                                itemToBe={itemToBe}
                                setItemToBe={setItemToBe}
                            />
                        )
                    })}
                </div>
                : <DataNotFound/>
            }
            <Create
                modalShow={modalCreate}
                setModalShow={setModalCreate}
                toast={toast}
                setToast={setToast}
                setLoading={setLoading}
            />
            <Update
                modalShow={modalUpdate}
                setModalShow={setModalUpdate}
                toast={toast}
                setToast={setToast}
                item={itemToBe}
                setLoading={setLoading}
            />
            <Delete
                modalShow={modalDelete}
                setModalShow={setModalDelete}
                toast={toast}
                setToast={setToast}
                item={itemToBe}
                setLoading={setLoading}
            />
            <ToastU toast={toast} setToast={setToast}/>
            <div className="mb-5"></div>
        </MainWrapper>
    );
}

export default Banners;