import { useTranslation } from 'react-i18next';

const SelectBankAccount = ({...props}) => {
    const { t } = useTranslation();

    const handleChange = (event) => {
        event.preventDefault();
        props.setValue(event.target.value);
    }

    return (
        <div className={`d-flex flex-column my-1 ${props.className}`}>
            <div className="d-flex flex-row medium font-13 mb-1">
                <span className="fg-dark me-1">{props.label}</span>
                <span className="fg-red-500">{props.required && '*'}</span>
            </div>
            <select
                size={props.size}
                value={props.value}
                onChange={handleChange}
                className={`rounded-2 border px-2 py-1 font-13 bg-light`}>
                <option value={""} disabled hidden>{t('CHOOSE')}</option>
                { props.items.map((item) => {
                    return (
                        <option value={item.id} key={item.id}>
                            {item.key}: {item.value} {item.currency.code} | {item.owner}
                        </option>
                    )
                }) }
            </select>
        </div>
    );
}

export default SelectBankAccount;