import { useEffect } from 'react';
import useAuth from './hooks/useAuth';
import useRefreshToken from './hooks/useRefreshToken';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Config from './config';

import ClientMain from './pages/Client/Main';

import Login from './pages/Admin/Login/Login';
import SignUp from './pages/Admin/Login/SignUp';
import VerifyOtp from './pages/Admin/Login/VerifyOtp';
import ResetPassword from './pages/Admin/Login/ResetPassword';

import Home from './pages/Admin/Main/pages/Home';
import Appointments from './pages/Admin/Main/pages/Appointments';
import Appointment from './pages/Admin/Main/pages/Appointments/Appointment';
import NewAppointment from './pages/Admin/Main/pages/Appointments/NewAppointment';
import Users from './pages/Admin/Main/pages/Users';
import User from './pages/Admin/Main/pages/Users/User';
import Employees from './pages/Admin/Main/pages/Employees';
import Banners from './pages/Admin/Main/pages/Banners';
import Services from './pages/Admin/Main/pages/Services';
import Supports from './pages/Admin/Main/pages/Supports';

import PageNotFound from './pages/PageNotFound';

import Profile from './pages/Admin/Settings/pages/Profile';
import PrivacyAndSecurity from './pages/Admin/Settings/pages/PrivacyAndSecurity';

import SettingsDataGeneral from './pages/Admin/Settings/pages/Data/General';
import Contacts from './pages/Admin/Settings/pages/Data/General/Contacts';
import PolicyDocuments from './pages/Admin/Settings/pages/Data/General/PolicyDocuments'
import Services2 from './pages/Admin/Settings/pages/Data/General/Services';
import ServiceDetail from './pages/Admin/Settings/pages/Data/General/Services/Detail';

import SettingsDataFinance from './pages/Admin/Settings/pages/Data/Finance';
import Banks from './pages/Admin/Settings/pages/Data/Finance/Banks';
import BankAccounts from './pages/Admin/Settings/pages/Data/Finance/BankAccounts';
import Currencies from './pages/Admin/Settings/pages/Data/Finance/Currencies';

import SettingsDataServices from './pages/Admin/Settings/pages/Data/Services';
import DocumentTypes from './pages/Admin/Settings/pages/Data/Services/DocumentTypes';
import Countries from './pages/Admin/Settings/pages/Data/Services/Countries';
import VisaTypes from './pages/Admin/Settings/pages/Data/Services/VisaTypes';
import TermTypes from './pages/Admin/Settings/pages/Data/Services/TermTypes';
import PreparationTimes from './pages/Admin/Settings/pages/Data/Services/PreparationTimes';
import RequiredDocuments from './pages/Admin/Settings/pages/Data/Services/RequiredDocuments';

function App() {
  const { auth } = useAuth();
  const refresh = useRefreshToken();

  const ADMIN_PREFIX = Config.ADMIN_PREFIX;

  useEffect(() => {
    refresh();
  }, [auth.token]);
  
  return (
    <BrowserRouter>
      <Routes>
        {/* public routes */}
        <Route path='/' element={<ClientMain/>} />

        {/* protected routes */}
        <Route path={`${ADMIN_PREFIX}/auth/signin`} element={<Login />} />
        <Route path={`${ADMIN_PREFIX}/auth/signup`} element={<SignUp />} />
        <Route path={`${ADMIN_PREFIX}/auth/verify-otp`} element={<VerifyOtp/>} />
        <Route path={`${ADMIN_PREFIX}/auth/reset-password`} element={<ResetPassword />} />

        <Route path={`${ADMIN_PREFIX}/`} element={<Home />} />
        <Route path={`${ADMIN_PREFIX}/appointments`} element={<Appointments />} />
        <Route path={`${ADMIN_PREFIX}/appointments/:id`} element={<Appointment />} />
        <Route path={`${ADMIN_PREFIX}/appointments/create`} element={<NewAppointment />} />
        <Route path={`${ADMIN_PREFIX}/users`} element={<Users />} />
        <Route path={`${ADMIN_PREFIX}/users/:id`} element={<User />} />
        <Route path={`${ADMIN_PREFIX}/employees`} element={<Employees />} />
        <Route path={`${ADMIN_PREFIX}/banners`} element={<Banners />} />
        <Route path={`${ADMIN_PREFIX}/services`} element={<Services />} />
        <Route path={`${ADMIN_PREFIX}/supports`} element={<Supports />} />

        <Route path={`${ADMIN_PREFIX}/settings/profile`} element={<Profile />} />
        <Route path={`${ADMIN_PREFIX}/settings/privacy-and-security`} element={<PrivacyAndSecurity />} />

        <Route path={`${ADMIN_PREFIX}/settings/data/general`} element={<SettingsDataGeneral />} />
        <Route path={`${ADMIN_PREFIX}/settings/data/general/contacts`} element={<Contacts />} />
        <Route path={`${ADMIN_PREFIX}/settings/data/general/policy-documents`} element={<PolicyDocuments />} />
        <Route path={`${ADMIN_PREFIX}/settings/data/general/services`} element={<Services2 />} />
        <Route path={`${ADMIN_PREFIX}/settings/data/general/services/:id`} element={<ServiceDetail />} />

        <Route path={`${ADMIN_PREFIX}/settings/data/finance`} element={<SettingsDataFinance />} />
        <Route path={`${ADMIN_PREFIX}/settings/data/finance/banks`} element={<Banks />} />
        <Route path={`${ADMIN_PREFIX}/settings/data/finance/bank-accounts`} element={<BankAccounts />} />
        <Route path={`${ADMIN_PREFIX}/settings/data/finance/currencies`} element={<Currencies />} />

        <Route path={`${ADMIN_PREFIX}/settings/data/services`} element={<SettingsDataServices />} />
        <Route path={`${ADMIN_PREFIX}/settings/data/services/document-types`} element={<DocumentTypes />} />
        <Route path={`${ADMIN_PREFIX}/settings/data/services/countries`} element={<Countries />} />
        <Route path={`${ADMIN_PREFIX}/settings/data/services/visa-types`} element={<VisaTypes />} />
        <Route path={`${ADMIN_PREFIX}/settings/data/services/term-types`} element={<TermTypes />} />
        <Route path={`${ADMIN_PREFIX}/settings/data/services/preparation-times`} element={<PreparationTimes />} />
        <Route path={`${ADMIN_PREFIX}/settings/data/services/required-documents`} element={<RequiredDocuments />} />

        {/* catch all */}
        <Route path='/page-not-found' element={<PageNotFound />} />
        <Route path='*' element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;