import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormCheck } from "react-bootstrap";
import Config from "../../../../../../config";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

const BankRadioBtn = ({...props}) => {
    const items = props.items;
    const { t } = useTranslation();

    const handleSelectedOption = (event) => {
        props.onChangeAttr(event.target.value);
    }

    return (
        <div className="d-flex row">
            <span className="font-13 medium fg-blue-300 mt-3 mb-2">
                <FontAwesomeIcon icon={faCircleInfo} className="me-2"/>
                {t('SELECT_BANK')}
                <span className="font-13 fg-red-500 ms-1">{props.required && '*'}</span>
            </span>
            { items.map((item) => {
                return(
                    <div key={item.id} className="col-md-3 card-default">
                        <div className={`rounded-3 border ${props.attr === item.id ?' bg-blue-50 border-primary' : 'bg-light'}`}>
                            <label className="d-flex align-items-center justify-content-between px-2 py-1 my-1">
                                <FormCheck 
                                    id={item.id}
                                    label={item.name}
                                    value={item.id}
                                    name={props.group}
                                    type={props.type}
                                    className={`bold ${props.attr === item.id ? 'fg-dark': 'fg-gray'} font-14 me-4 my-0`}
                                    checked={props.attr === item.id}
                                    onChange={handleSelectedOption}/>
                                <img src={`${Config.URL_SERVER}${Config.PATH_PREFIX}${Config.PATH_BANKS}${item.logo}`} className="bank-logo-sm"/>
                            </label>
                        </div>
                    </div>
                )
            })}
        </div>
    );
}

export default BankRadioBtn;