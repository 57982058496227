import Wrapper from "./../../../../layouts/Admin/Wrapper";
import NavBar from "./../../../../layouts/Admin/NavBar";
import Keys from "./../../../../utils/constants";
import icLayoutGrid from './../../../../assets/svg/ic_layout_grid.svg';
import icFolders from './../../../../assets/svg/ic_folders.svg';
import icSlideshow from './../../../../assets/svg/ic_slideshow.svg';
import icUserOutline from './../../../../assets/svg/ic_user_outline2.svg';
import icInstructorOutline from './../../../../assets/svg/ic_instructor_outline.svg';
import icFileCloud from './../../../../assets/svg/ic_file_cloud.svg';
import icEmail from './../../../../assets/svg/ic_email.svg';
import { useTranslation } from "react-i18next";

const MainWrapper = ({...props}) => {
    const { t } = useTranslation();

    let items = [
        { name: t('HOME'), id: "home", icon: icLayoutGrid, links: Keys.HOME_LINKS },
        { name: t('APPOINTMENTS'), icon: icFileCloud, id: "appointments", links: Keys.APPOINTMENTS_LINKS },
        { name: t('BANNERS'), icon: icSlideshow, id: "banners", links: Keys.BANNERS_LINKS },
        { name: t('USERS'), icon: icUserOutline, id: "users", links: Keys.USERS_LINKS },
        { name: t('EMPLOYEES'), icon: icInstructorOutline, id: "employees", links: Keys.EMPLOYEES_LINKS },
        { name: t('SERVICES'), icon: icFolders, id: "services", links: Keys.SERVICES_LINKS },
        { name: t('SUPPORT_REQUESTS'), icon: icEmail, id: "support_requests", links: Keys.SUPPORTS_REQUESTS_LINKS },
    ];

    return (
        <Wrapper rootPage={props.rootPage} loading={props.loading} breadcrumbs={props.breadcrumbs} toast={props.toast} setToast={props.setToast}>
            <div className="main-page">
                <NavBar items={items} wrapper={'MAIN'}/>
                <div className="body-page">
                    {props.children}
                </div>
            </div>
        </Wrapper>
    );
};

export default MainWrapper;