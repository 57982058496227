import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faXmarkCircle } from "@fortawesome/free-regular-svg-icons";
import { useTranslation } from "react-i18next";

const Status = ({...props}) => {
    const { t } = useTranslation();
    const status = props.status;
    let style = '';

    if (status) style={look: 'fg-green-500', icon: faCircleCheck}
    else style={look: 'fg-red-300', icon: faXmarkCircle}

    return (
        <span className={`rounded-1 px-2 py-1 medium ${props.textSize ? props.textSize : "small-x"} ${style.look}`}>
            {status ? t('YES') : t('NO')}
            <FontAwesomeIcon className="ms-1" icon={style.icon}/>
        </span>
    );
}

export default Status;