import icHrDot from './../../../../../../assets/svg/ic_hr_dot.svg';
import icPen from './../../../../../../assets/svg/ic_pen.svg';
import icTrash from './../../../../../../assets/svg/ic_trash.svg';
import DropdownU2 from '../../../../components/ui/DropdownU2';
import { useTranslation } from "react-i18next";
import Config from '../../../../../../config';

const BannerCard = ({banner, setModalUpdate, setModalDelete, setItemToBe}) => {
    const { t } = useTranslation();
   
    const options = [
        {
            name: t('EDIT'),
            icon: icPen,
            color: "fg-dark",
            onClick: () => {
                setItemToBe(banner);
                setModalUpdate(true);
            }
        },
        {
            name: t('DELETE'),
            icon: icTrash,
            color: "fg-dark",
            onClick: () => { 
                setItemToBe(banner);
                setModalDelete(true);
            }
        }
    ]

    return (
        <div className="d-flex flex-column col-md-3 position-relative my-2">
            <img src={`${Config.URL_SERVER}${Config.PATH_PREFIX}${Config.PATH_BANNERS}${banner.image}`} className="rounded-3" style={{height: '150px'}}/>
            <div className="d-flex flex-row justify-content-between col-md-11 position-absolute">
                <span></span>
                <span></span>
                <DropdownU2 className='d-flex align-items-center justify-content-center bg-light rounded-circle m-2' toggleClass={"p-1"} icon={icHrDot} items={options}/>
            </div>
        </div>
    );
}

export default BannerCard;