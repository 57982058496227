import Config from './../config';

export default class Keys {
    // static USERNAME_REGEX = /^[a-zA-Z][a-zA-Z0-9-_]{5,31}$/;
    static USERNAME_REGEX = /^[a-zA-Z][a-zA-Z0-9-_.]{3,31}$/;
    static EMAIL_REGEX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    static OTP_REGEX = /^[0-9]{2,5}$/;
    // static PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%&]).{8,255}$/;
    static PASSWORD_REGEX = /^[ -~]{3,255}$/;
    static NAME_REGEX = /^[\p{L}\p{N}\p{M}&.\s-]{3,255}$/u;
    static PHONE_NUMBER_REGEX = /^[6-7][1-5][0-9]{6}$/; // starts with 6, second digit in range(1-5), then any number
    static UNSIGNED_DOUBLE_REGEX = /^[0-9.]{0,14}$/;
    static UNSIGNED_INTEGER_REGEX = /^[0-9]{0,100}$/;

    static UNAUTHORIZED = "Unauthorized";

    // For App passwords / Roles
    static ADMINISTRATION = "Administration"; // Administration

    static ROOT = "ROOT"; // Root
    static ADMIN = "ADMIN"; // Admin

    static ENUM_APPOINTMENT_STATE = [
        { id: '0', state: 'REVIEWING'},
        { id: '1', state: 'ACCEPTED'},
        { id: '2', state: 'IN_PROCESS'},
        { id: '3', state: 'INVITATION'},
        { id: '4', state: 'COMPLETED'},
        { id: '5', state: 'REJECTED'},
    ];

    static APPOINTMENT_STATE = new Map(
        [
            [ '0', 'REVIEWING'],
            [ '1', 'ACCEPTED'],
            [ '2', 'IN_PROCESS'],
            [ '3', 'INVITATION'],
            [ '4', 'COMPLETED'],
            [ '5', 'REJECTED'],
        ]
    );

    static ENUM_POLICY_DOCUMENT_TYPES = [
        { id: '0', state: 'TERMS_OF_SERVICE'},
        { id: '1', state: 'PRIVACY_POLICY'},
        { id: '2', state: 'CONTRACT'},
    ];

    // static POLICY_DOCUMENT_TYPES = new Map([
    //     ['TERMS_OF_SERVICE', this.TERMS_OF_SERVICE], ['PRIVACY_POLICY', this.PRIVACY_POLICY], ['CONTRACT', this.CONTRACT]
    // ]);
     
    // ---------------------- LINKS ------------------------------------
    // Links Main
    static HOME_LINKS = [ `${Config.ADMIN_PREFIX}` ];
    
    static APPOINTMENTS_LINKS = [ `${Config.ADMIN_PREFIX}/appointments` ];

    static BANNERS_LINKS = [ `${Config.ADMIN_PREFIX}/banners` ];

    static USERS_LINKS = [ `${Config.ADMIN_PREFIX}/users`, `${Config.ADMIN_PREFIX}/users/:id`, `${Config.ADMIN_PREFIX}/users/create` ];

    static EMPLOYEES_LINKS = [ `${Config.ADMIN_PREFIX}/employees` ];

    static SERVICES_LINKS = [ `${Config.ADMIN_PREFIX}/services` ];

    static SUPPORTS_REQUESTS_LINKS = [ `${Config.ADMIN_PREFIX}/supports` ];

    // Links Settings -------------------------------------------
    
    static SETTINGS_LINKS = [
        `${Config.ADMIN_PREFIX}/settings/profile`,
        `${Config.ADMIN_PREFIX}/settings/privacy-and-security`,
        `${Config.ADMIN_PREFIX}/settings/data`,
    ];

    static SETTINGS_DATA_GENERAL_LINKS = [
        `${Config.ADMIN_PREFIX}/settings/data/general`,
        `${Config.ADMIN_PREFIX}/settings/data/general/contacts`,
        `${Config.ADMIN_PREFIX}/settings/data/general/policy-documents`,
        `${Config.ADMIN_PREFIX}/settings/data/general/services`,
    ];

    static SETTINGS_DATA_SERVICES_LINKS = [
        `${Config.ADMIN_PREFIX}/settings/data/services`,
        `${Config.ADMIN_PREFIX}/settings/data/services/document-types`,
        `${Config.ADMIN_PREFIX}/settings/data/services/countries`,
        `${Config.ADMIN_PREFIX}/settings/data/services/visa-types`,
        `${Config.ADMIN_PREFIX}/settings/data/services/term-types`,
        `${Config.ADMIN_PREFIX}/settings/data/services/preparation-times`,
        `${Config.ADMIN_PREFIX}/settings/data/services/required-documents`,
    ];

    static SETTINGS_DATA_FINANCE_LINKS = [
        `${Config.ADMIN_PREFIX}/settings/data/finance`,
        `${Config.ADMIN_PREFIX}/settings/data/finance/banks`,
        `${Config.ADMIN_PREFIX}/settings/data/finance/bank-accounts`,
        `${Config.ADMIN_PREFIX}/settings/data/finance/currencies`,
    ];

    static SETTINGS_DATA_LINKS = this.SETTINGS_DATA_GENERAL_LINKS.concat(this.SETTINGS_DATA_SERVICES_LINKS, this.SETTINGS_DATA_FINANCE_LINKS);
}