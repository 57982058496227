import { useState, useEffect } from "react";
import useAuth from "../../../../../../hooks/useAuth";
import useAxiosPrivate from '../../../../../../hooks/useAxiosPrivate';
import { useParams } from "react-router-dom";
import Utils from "../../../../../../utils";
import ToastU from "../../../../../Admin/components/ui/ToastU";
import MainWrapper from './../../../layouts/MainWrapper';
import InputLabeled from '../../../../../Admin/components/form/InputLabeled';
import SwitchLabeled from "../../../../../Admin/components/form/SwitchLabeled";
import { useTranslation } from "react-i18next";
import Config from "../../../../../../config";

const User = ({...props}) => {
    const { ready } = useAuth();
    const axiosPrivate = useAxiosPrivate();
    const params = useParams();
    const { t } = useTranslation();
  
    const [loading, setLoading] = useState(false);

    const [user, setUser] = useState({});
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [verified, setVerified] = useState(false);
    const [admin, setAdmin] = useState(false);

    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPasswor] = useState('');

    const [toast, setToast] = useState({ show: false, content: '', variant: '', title: ''});

    const breadcrumbs = [
        { title: t('MAIN'), link: `${Config.ADMIN_PREFIX}/`},
        { title: t('USERS'), link: `${Config.ADMIN_PREFIX}/users` },
        { title: user.fullName, active: true },
    ]

    useEffect(() => {
        if (ready) {
            getUser();
        }
    }, [ready, toast]);

    const getUser = () => {
        setLoading(true);
        axiosPrivate.get('/users/'+params.id).then((res) => {
            if (res.status == 200) {
                setUser(res.data);
            }
        }).finally(() => {setLoading(false)});
    }

    useEffect(() => {
        if (user) {
            setFirstName(user.firstName);
            setLastName(user.lastName);
            setEmail(user.email);
            setVerified(user.userAuth && user.userAuth.verified);
            setAdmin(user.userAuth && user.userAuth.admin);
        }
    }, [user]);

    const handleSubmit = () => {
        const requiredInputs = [
            { key: firstName, value: t('NAME_REQUIRED') },
            { key: lastName, value: t('LASTNAME_REQUIRED') },
        ]

        const content = Utils.errorHandling(requiredInputs);
        if (content) {
            setToast({ content, variant: 'danger', title: t('ERROR'), show: true });
            return;
        }

        const data = { firstName, lastName, phoneNumber, admin };

        axiosPrivate.put('/users/update/'+user.id, data).then((res) => {
            if (res.status === 200) {
                setToast({ content: t('SUCCESS'), variant: 'success', title: t('SUCCESS'), show: true });
            }
        }).catch((err) => {
            if (!err?.response) props.setToast({ content: t('NO_SERVER_RESPONSE'), variant: 'danger', title: t('ERROR'), show: true });
            else setToast({ content: err.response.data.msg, variant: 'danger', title: t('ERROR'), show: true });
        }).finally(() => {setLoading(false)});
    }

    const handlePrivacyChange = () => {
        const data = { verified, admin };

        axiosPrivate.put('/auth/update/'+user.id, data).then((res) => {
            if (res.status === 200) {
                setToast({ content: t('SUCCESS'), variant: 'success', title: t('SUCCESS'), show: true });
            }
        }).catch((err) => {
            if (!err?.response) setToast({ content: t('NO_SERVER_RESPONSE'), variant: 'danger', title: t('ERROR'), show: true });
            else setToast({ content: err.response.data.msg, variant: 'danger', title: t('ERROR'), show: true });
        }).finally(() => {setLoading(false)});
    }

    const handlePasswordChange = () => {
        const requiredInputs = [
            { key: currentPassword, value: t('CURRENT_PASSWORD_REQUIRED') },
            { key: newPassword, value: t('NEW_PASSWORD_REQUIRED') },
            { key: confirmNewPassword, value: t('CONFIRM_NEW_PASSWORD_REQUIRED') },
            { key: newPassword === confirmNewPassword, value: t('MATCH_PASSWORD_INSTRUCTION') }
        ]

        const content = Utils.errorHandling(requiredInputs);
        if (content) {
            setToast({ content, variant: 'danger', title: t('ERROR'), show: true });
            return;
        }

        const data = { currentPassword, newPassword, confirmNewPassword };

        axiosPrivate.put('/auth/change-password/'+user.id, data).then((res) => {
            if (res.status == 200) {
                setToast({ content: t('PASSWORD_UPDATED'), variant: 'success', title: t('SUCCESS'), show: true });
                setCurrentPassword('');
                setNewPassword('');
                setConfirmNewPasswor('');
            }
        }).catch((err) => {
            if (!err?.response) props.setToast({ content: t('NO_SERVER_RESPONSE'), variant: 'danger', title: t('ERROR'), show: true });
            else setToast({ content: err.response.data.msg, variant: 'danger', title: t('ERROR'), show: true });
        }).finally(() => {setLoading(false)});
    }
 
    return (
        <MainWrapper loading={loading} breadcrumbs={breadcrumbs}>
            <div className="m-3">
                <form className="row">
                    <div className='col-md-12 mb-3'>
                        <span className='medium font-18 fg-blue-700'>{t('USER')}</span>
                        <span onClick={handleSubmit} className='text-primary medium ms-4 bg-light border rounded-2 font-12 px-2 hover-cursor'>{t('UPDATE')}</span>
                    </div>
                    <InputLabeled label={t('EMAIL')} type={'text'} value={email} setValue={setEmail} hint={t('EMAIL')} className={'col-md-3'} disabled={true}/>
                    <div className="col-md-9"></div>
                    <InputLabeled label={t('FIRSTNAME')} type={'text'} value={firstName} setValue={setFirstName} hint={t('FIRSTNAME')} className={'col-md-3'} required={true}/>
                    <InputLabeled label={t('LASTNAME')} type={'text'} value={lastName} setValue={setLastName} hint={t('LASTNAME')} className={'col-md-3'} required={true}/>
                    <InputLabeled label={t('PHONE_NUMBER')} type={'text'} value={phoneNumber} setValue={setPhoneNumber} hint={t('PHONE_NUMBER')} className={'col-md-3'} required={false}/>
                    <hr className='mt-5'/>
                    <div className='col-md-12'>
                        <span className='medium font-18 fg-blue-700'>{t('PRIVACY')}</span>
                        <span onClick={handlePrivacyChange} className='text-primary medium ms-4 bg-light border rounded-2 font-12 px-2 hover-cursor'>{t('UPDATE')}</span>
                    </div>
                    <SwitchLabeled label={t('VERIFIED')} value={verified} setValue={setVerified} size="small" className={'col-md-1'} required={false}/>
                    <SwitchLabeled label={t('ADMIN')} value={admin} setValue={setAdmin} size="small" className={'col-md-1'} required={false}/>
                    <hr className='mt-5'/>
                    <div className='col-md-12'>
                        <span className='medium font-18 fg-blue-700'>{t('SECURITY')}</span>
                        <span onClick={handlePasswordChange} className='text-primary medium ms-4 bg-light border rounded-2 font-12 px-2 hover-cursor'>{t('UPDATE')}</span>
                    </div>
                    <InputLabeled label={`${t('CURRENT')} ${t('PASSWORD')}`} type={'text'} value={currentPassword} setValue={setCurrentPassword} className={'col-md-3'} bg={'bg-yellow-100'} required={true}/>
                    <InputLabeled label={`${t('NEW')} ${t('PASSWORD')}`} type={'text'} value={newPassword} setValue={setNewPassword} className={'col-md-3'} bg={'bg-yellow-100'} required={true}/>
                    <InputLabeled label={`${t('NEW')} ${t('PASSWORD')} ${t('CONFIRM')}`} type={'text'} value={confirmNewPassword} setValue={setConfirmNewPasswor} className={'col-md-3'} bg={'bg-yellow-100'} required={true}/>
                </form>
            </div>
            <ToastU toast={toast} setToast={setToast}/>
        </MainWrapper>
    );
}

export default User;