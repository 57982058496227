import AppointmentStates from "./../components/AppointmentStates";

const State = ({...props}) => {
    const appointmentStates = AppointmentStates();
    const state = appointmentStates.get(props.state);
    let style = '';

    switch (props.state) {
        case 'REVIEWING': style = 'bg-gray-50 text-dark'; break;
        case 'ACCEPTED': style = 'bg-blue-100 fg-blue-500'; break;
        case 'IN_PROCESS': style = 'bg-orange-100 fg-orange-700'; break;
        case 'INVITATION': style = 'bg-purple-100 fg-purple-500'; break;
        case 'COMPLETED': style = 'bg-green-100 fg-green-700'; break;
        case 'REJECTED': style = 'bg-red-100 fg-red-500'; break;
        default: break;
    }

    return (
        <span className={`rounded-4 px-2 py-1 ${style}`}>{state}</span>
    );
}

export default State;