import { useEffect, useState } from "react";
import convertSize from "convert-size";
import useAxiosPrivate from '../../../../../../../hooks/useAxiosPrivate';
import ModalU from '../../../../../components/ui/ModalU';
import InputAreaLabeled from "../../../../../components/form/InputAreaLabeled";
import { useTranslation } from "react-i18next";
import Config from "../../../../../../../config";
import icPdf from "../../../../../../../assets/svg/ic_pdf.svg"

const UpdateReceipt = ({...props}) => {
    const { t } = useTranslation();
    const axiosPrivate = useAxiosPrivate();
    
    const item = props.item;
    
    const [note, setNote] = useState('');
    const [receipt, setReceipt] = useState();
    const [file, setFile] = useState();

    const handleFile = (e) => {
        setFile(e.target.files[0]);
    }

    useEffect(() => {
        setNote(item.note);
        setReceipt(item.receipt);
    }, [item]);

    const handleSubmit = (event) => {
        event.preventDefault();

        props.setLoading(true);
        const data = {
            path: Config.PATH_RECEIPTS,
            filename: item.receipt,
            file,
        };
        
        const headers = { 'Content-Type': 'multipart/form-data'};
        axiosPrivate.put('/appointment-payments/update/'+item.id, data, {headers}).then((res) => {
            if (res.status == 200) {
                props.setModalShow(false);
                props.setToast({ content: t('SUCCESS'), variant: 'success', title: t('SUCCESS'), show: true });
            }
        }).catch((err) => {
            if (!err?.response) props.setToast({ content: t('NO_SERVER_RESPONSE'), variant: 'danger', title: t('ERROR'), show: true });
            else props.setToast({ content: err.response.data.msg, variant: 'danger', title: t('ERROR'), show: true });
        }).finally(() => {props.setLoading(false)});
    }

    return (
        <ModalU show={props.modalShow} size={''} title={`${t('PAYMENT')} [${t('EDIT')}]`}
            actionbtntext={t('UPDATE')}
            actionbtnclassname={"bg-green-700 font-14 medium text-white"}
            onSubmit={handleSubmit} onHide={props.setModalShow}>
                
            <form onSubmit={handleSubmit}>
                <span className="fg-dark font-13 medium">{t('RECEIPT')}</span>
                <div className="d-flex flex-column align-items-center mb-2 bg-orange-50 p-3">
                    <img src={icPdf} alt="excel" className="wh-40"/>
                    {file &&
                        <div className="d-flex flex-column m-1">
                            <span className="text-secondary font-14">{t('NAME')}:<span className="fg-blue-500 ms-2">{file.name}</span></span>
                            <span className="text-secondary font-14">{t('SIZE')}:<span className="fg-green-500 medium ms-2">{convertSize(file.size, {accuracy: 3})}</span></span>
                        </div>
                    }
                    <input id="uploadBtn" type="file" accept="application/pdf" className="small-x mt-3" onChange={handleFile}/>
                </div>
                <InputAreaLabeled label={t('NOTE')} rows={3} value={note} setValue={setNote} hint={t('NOTE')} required={false}/>
            </form>
        </ModalU>
    );
}

export default UpdateReceipt