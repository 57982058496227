import Config from "./../../../config";
import { Link} from 'react-router-dom';
import icLogo from "./../../../assets/svg/ic_logo.svg";

const Main = () => {
    return (
        <div className="d-flex flex-column align-items-center justify-content-center p-5">
            <img src={icLogo} className="wh-200" />
            {/* <span className="fs-1 medium fg-dark m-5">{Config.APP_NAME}</span> */}
        </div>
    );
}

export default Main;